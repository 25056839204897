import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import { CustomerContext } from "../../controllers/contexts/customer.context";
import { IDocuments, IProducts } from "../../common/models";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { FaEye } from "react-icons/fa6";
import { GenericHelperService } from "../../api/generics/helper.service";
import { PurchaseContext } from "../../controllers/contexts/purchase.context";
import { PurchaseStatuses } from "../../common/constants/generalConstants";
import moment from "moment";
import Select from "react-select";
import {
  getDateIfValid,
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import dayjs from "dayjs";
const PurchaseDetailsComponent = () => {
  const helperService: GenericHelperService = new GenericHelperService();
  const {
    purchaseForm,
    clearViewPurchase,
    supplierList,
    createNewPurchaseOrder,
    savePurchaseOrder,
    productsList,
    productCateList,
  } = useContext(PurchaseContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [poDetails, setPoDetails] = useState(purchaseForm);
  const [suplierOptions, setSuplierOptions] = useState<any>([]);
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const [selectedProducts, setSelectedProducts] = useState<any>({
    poNum: 0,
    supplierId: null,
    poCreatedDate: new Date(),
    expDelDate: new Date(),
    amount: 0,
    taxAmount: 0,
    refNumber: "",
    status: "DRAFT",
    poDetails: [],
    purchaseOrderNumber: "",
    deleted: false,
  });
  const isEditablePo = location.state.isEditable;
  useEffect(() => {
    if (purchaseForm === null) {
      navigate(RoutingConstants.purchase);
    }
  }, [purchaseForm]);
  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };
  useEffect(() => {
    if (poDetails) {
      const updatedProductsList = { ...poDetails }; // Create a copy
      let newList = updatedProductsList.poDetails.filter((item) => {
        return item.quantity !== 0;
      });
      updatedProductsList.poDetails = newList;
      setPoDetails(updatedProductsList);
    }
  }, []);
  useEffect(() => {
    if (supplierList.length > 0) {
      let suplierOptions = [];
      supplierList.map((supp: any) => {
        suplierOptions.push({
          value: supp.supId,
          label: supp.supName,
        });
      });
      setSuplierOptions(suplierOptions);
    }
  }, []);
  //// Function to handle checkbox clicks
  const handleCheckboxClick = (product: IProducts) => {
    const updatedProductsList = { ...poDetails }; // Create a copy
    const productIndex = updatedProductsList.poDetails.findIndex(
      (p) => p.prodId === product.productId
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList.poDetails.splice(productIndex, 1); // Remove from the list
    } else {
      // Item not in the list
      updatedProductsList.poDetails.push({
        prodId: product.productId,
        prodCatId: JSON.parse(product.prodCategory),
        quantity: 0,
        price: 0,
        vat: 0,
        carriageAmount: 0,
        totalAmount: 0,
        vatPercentage: 0,
      }); // Add to the list with isChecked flag
    }
    setPoDetails(updatedProductsList);
  };
  const handleDeletePr = (id: number) => {
    if (!isEditablePo) return;
    const updatedProductsList = { ...poDetails }; // Create a copy
    const productIndex = updatedProductsList.poDetails.findIndex(
      (p) => p.prodId === id
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList.poDetails.splice(productIndex, 1); // Remove from the list
    }
    setPoDetails(updatedProductsList);
  };
  const handleQuantityChange = (e: any, product: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.poDetails.map((_prod, i) => {
      if (_prod.prodId === product.prodId) {
        _prod.quantity = e.target.value;
        _prod.carriageAmount = _prod.price * e.target.value;
        if (_prod.vatPercentage) {
          _prod.vat = (_prod.carriageAmount / 100) * _prod.vatPercentage;
          _prod.totalAmount = _prod.carriageAmount + _prod.vat;
        }
      }
    });
    setPoDetails(_purchaseOrder);
  };
  const handlePriceChange = (e: any, product: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.poDetails.map((_prod, i) => {
      if (_prod.prodId === product.prodId) {
        _prod.price = e.target.value;
        _prod.carriageAmount = _prod.quantity * e.target.value;
        if (_prod.vatPercentage) {
          _prod.vat = (_prod.carriageAmount / 100) * _prod.vatPercentage;
          _prod.totalAmount = _prod.carriageAmount + _prod.vat;
        }
      }
    });
    setPoDetails(_purchaseOrder);
  };
  const handleVatPrChange = (e: any, product: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.poDetails.map((_prod, i) => {
      if (_prod.prodId === product.prodId) {
        _prod.vatPercentage = e.target.value;
        _prod.carriageAmount = _prod.price * _prod?.quantity;
        _prod.vat = (_prod.carriageAmount / 100) * _prod.vatPercentage;
        _prod.totalAmount = _prod.carriageAmount + _prod.vat;
      }
    });
    setPoDetails(_purchaseOrder);
  };
  const handleSuppName = (e: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.supplierId = e.target.value;
    setPoDetails(_purchaseOrder);
  };
  const calculateTotalTaxVal = () => {
    let _purchaseOrder = { ...poDetails };
    let totalTax = 0;
    _purchaseOrder.poDetails.map((_prod, i) => {
      totalTax = totalTax + _prod.vat;
    });
    return totalTax.toFixed(2);
  };
  const calculateTotalAmountVal = () => {
    let _purchaseOrder = { ...poDetails };
    let totalAmt = 0;
    _purchaseOrder.poDetails.map((_prod, i) => {
      totalAmt = totalAmt + _prod.totalAmount;
    });
    totalAmt = totalAmt + parseInt(_purchaseOrder.carriageAmount);
    return totalAmt.toFixed(2);
  };
  const handleRefNo = (e: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.refNumber = e.target.value;
    setPoDetails(_purchaseOrder);
  };
  const handleStatus = (e: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.status = e.target.value;
    setPoDetails(_purchaseOrder);
  };
  const handleCarAmt = (e: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.carriageAmount = e.target.value ? e.target.value : 0;
    setPoDetails(_purchaseOrder);
  };
  const handleExpDelDate = (e: any) => {
    let _purchaseOrder = { ...poDetails };
    _purchaseOrder.expDelDate = e;
    setPoDetails(_purchaseOrder);
  };
  const handleUpdatePurchaseOrder = () => {
    let _updatedPo = { ...poDetails };
    if (_updatedPo.poDetails.length > 0) {
      if (!_updatedPo?.expDelDate?.includes("T")) {
        let date = new Date().toISOString().split("T");
        _updatedPo.expDelDate = _updatedPo?.expDelDate + "T" + date[1];
      }
      _updatedPo.taxAmount = JSON.parse(calculateTotalTaxVal());
      _updatedPo.amount = JSON.parse(calculateTotalAmountVal());
      delete _updatedPo["createdDate"];
      delete _updatedPo["createdBy"];
      delete _updatedPo["modifiedDate"];
      delete _updatedPo["modifiedBy"];
      savePurchaseOrder(_updatedPo);
      navigate(RoutingConstants.purchase);
    } else {
      toast.error("Please add products to purchase order");
    }
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${LabelConstants.Customer} ${LabelConstants.Details}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderIn}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.purchase}
                      title={LabelConstants.PurchaseOrder}
                    >
                      {LabelConstants.PurchaseOrder}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.PurchaseOrder} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {poDetails ? (
        <>
          <section className="content">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="box">
                  <div className="box-header  bg-primary d-flex justify-content-between">
                    <h4 className="box-title">Products </h4>
                    {isEditablePo && poDetails.status === "DRAFT" && (
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-right"
                      >
                        Add Product
                      </button>
                    )}
                  </div>
                  <div className="box-body">
                    {/* products Table */}
                    <div className="table-responsive">
                      <table className="table product-overview table-bordered text-center">
                        <thead className="table-primary">
                          <tr>
                            <th>Product Category</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Price/ unit</th>
                            <th className="text-align:center">VAT(%)</th>
                            <th className="text-align:center">Tax</th>
                            <th className="text-align:center">Amount</th>
                            <th className="text-align:center">Actions</th>
                          </tr>
                        </thead>
                        {
                          <tbody>
                            {poDetails.poDetails.length > 0 ? (
                              poDetails.poDetails.map(
                                (product: any, i: number) => (
                                  <tr key={i}>
                                    <td className="text-start">
                                      {getProductCatNameById(
                                        product.prodCatId,
                                        productCateList
                                      )}
                                    </td>
                                    <td className="text-start">
                                      {getProductNameById(
                                        product.prodId,
                                        productsList
                                      )}{" "}
                                    </td>
                                    <td className="w-150">
                                      <input
                                        type="number"
                                        className="form-control text-start"
                                        readOnly={
                                          isEditablePo &&
                                          poDetails.status === "DRAFT"
                                            ? false
                                            : true
                                        }
                                        min={0}
                                        onChange={(e) => {
                                          handleQuantityChange(e, product);
                                        }}
                                        value={product.quantity}
                                      />
                                    </td>
                                    <td className="w-150">
                                      <input
                                        type="number"
                                        className="form-control text-start"
                                        readOnly={
                                          isEditablePo &&
                                          poDetails.status === "DRAFT"
                                            ? false
                                            : true
                                        }
                                        min={0}
                                        onChange={(e) => {
                                          handlePriceChange(e, product);
                                        }}
                                        value={product.price}
                                      />
                                    </td>
                                    <td className="w-90">
                                      <input
                                        type="number"
                                        className="form-control"
                                        readOnly={
                                          isEditablePo &&
                                          poDetails.status === "DRAFT"
                                            ? false
                                            : true
                                        }
                                        min={0}
                                        value={
                                          product.vatPercentage
                                            ? product.vatPercentage
                                            : 0
                                        }
                                        onChange={(e) =>
                                          handleVatPrChange(e, product)
                                        }
                                      />
                                    </td>
                                    <td>
                                      {product.vat ? product.vat.toFixed(2) : 0}
                                    </td>
                                    <td>
                                      {product.totalAmount
                                        ? product.totalAmount.toFixed(2)
                                        : 0}
                                    </td>
                                    <td>
                                      <span
                                        onClick={() =>
                                          handleDeletePr(product.prodId)
                                        }
                                      >
                                        <i
                                          className="fa fa-trash text-danger fs-3"
                                          aria-hidden="true"
                                          style={{
                                            opacity: !isEditablePo ? 0.5 : 1,
                                          }}
                                        ></i>
                                      </span>
                                    </td>
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={9} align="center">
                                  <br />
                                  <p>No products added to purchase order</p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        }
                      </table>

                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          clearViewPurchase();
                          setPoDetails(null);
                          navigate(RoutingConstants.purchase);
                        }}
                      >
                        Close
                      </button>
                      {isEditablePo && (
                        <button
                          type="button"
                          className="btn btn-primary float-end"
                          onClick={() => handleUpdatePurchaseOrder()}
                        >
                          Save changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* purchase order container*/}
              <div className="col-12 col-lg-4">
                <div className="box">
                  <div className="box-header bg-info p-3">
                    <h4 className="box-title">Purchase Order Details</h4>
                    <p className="p-0 m-0">
                      #{LabelConstants.PoNumber} :{" "}
                      {poDetails.purchaseOrderNumber}
                    </p>
                  </div>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.SupplierName}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        {/* <input
                                            type="text"
                                            className="form-control"
                                            value={poDetails.supplierId}
                                            readOnly
                                        /> */}
                        {/* <select
                          className="form-select"
                          onChange={(e) => {
                            handleSuppName(e);
                          }}
                          disabled
                          value={poDetails.supplierId}
                        >
                          <option>--</option>
                          {supplierList.map((supp: any, i: number) => {
                            return (
                              <option key={supp.supId} value={supp.supId}>
                                {supp.supName}
                              </option>
                            );
                          })}
                        </select> */}

                        {suplierOptions.length > 0 && (
                          <Select
                            value={suplierOptions.filter(
                              (option) => option.value === poDetails.supplierId
                            )}
                            options={suplierOptions}
                            isDisabled={true}
                            onChange={(e) => handleSuppName(e)}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.CreatedDate}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          className="form-control"
                          readOnly
                          value={moment(poDetails?.poCreatedDate).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.ExpectedDeliveryDate}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <DatePicker
                          className="form-control bg-white"
                          value={getDateIfValid(poDetails?.expDelDate)}
                          disabled={!isEditablePo}
                          format="DD-MM-YYYY"
                          onChange={(e, ds) => {
                            let date = e?.format("YYYY-MM-DD");
                            handleExpDelDate(date);
                          }}
                        />
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label
                          className="form-label fw-bold"
                          htmlFor="goodsReceiptNumber"
                        >
                          {LabelConstants.Status}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          className="form-select"
                          value={poDetails.status}
                          onChange={(e) => {
                            handleStatus(e);
                          }}
                          disabled={!isEditablePo}
                        >
                          {purchaseForm.status === "DRAFT" ? (
                            <>
                              <option value={"DRAFT"}>DRAFT</option>
                              <option value={"SUBMITTED"}>SUBMITTED</option>
                            </>
                          ) : purchaseForm.status === "SUBMITTED" ? (
                            <>
                              <option value={"SUBMITTED"}> SUBMITTED </option>
                              <option value={"CANCEL"}>CANCEL</option>
                            </>
                          ) : purchaseForm.status === "PART_FILL" ? (
                            <>
                              <option value={"PART_FILL"}>PART_FILL</option>
                              <option value={"CLOSED"}>CLOSED</option>
                            </>
                          ) : purchaseForm.status === "FULL_FILL" ? (
                            <>
                              <option value={"FULL_FILL"}>FULL_FILL</option>
                              <option value={"CLOSED"}>CLOSED</option>
                            </>
                          ) : purchaseForm.status === "CLOSED" ? (
                            <option value={"CLOSED"}>CLOSED</option>
                          ) : purchaseForm.status === "CANCEL" ? (
                            <option value={"CANCEL"}>CLOSED</option>
                          ) : (
                            ""
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label
                          className="form-label fw-bold"
                          htmlFor="goodsReceiptNumber"
                        >
                          {LabelConstants.referenceNumber}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          className="form-control"
                          readOnly={!isEditablePo}
                          value={poDetails.refNumber}
                          onChange={(e) => handleRefNo(e)}
                        />
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label
                          className="form-label fw-bold"
                          htmlFor="goodsReceiptNumber"
                        >
                          Carriage Amount
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          type="number"
                          min={0}
                          className="form-control"
                          readOnly={!isEditablePo}
                          value={poDetails.carriageAmount}
                          onChange={(e) => handleCarAmt(e)}
                        />
                      </div>
                    </div>
                    <div className=" d-flex mt-20">
                      <div className="col-4">
                        <h5 className="fw-bold">Tax</h5>
                      </div>
                      <div className="col-8">
                        <p className="text-end fw-700 h6">
                          {LabelConstants.DollarSign}{" "}
                          {isEditablePo
                            ? calculateTotalTaxVal()
                            : poDetails.taxAmount}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mt-20">
                      <div className="col-4">
                        <h5 className="bt-1 fw-bold">Payable Amount</h5>
                      </div>
                      <div className="col-8">
                        <p className="bt-1 text-end fw-900 fs-18">
                          {LabelConstants.DollarSign} {calculateTotalAmountVal()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Products list modal */}
          <div
            className="modal modal-right fade overflow-auto"
            id="modal-right"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Products List</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ overflowY: "auto" }}>
                  <input
                    type="search"
                    placeholder="Search Product"
                    value={searchProduct}
                    className="form-control"
                    onChange={onClickSearch}
                  />
                  <br />
                  <ul className="list-group">
                    {filteredProducts?.length > 0 &&
                      filteredProducts.map((product: IProducts) => (
                        <li
                          className={`list-group-item ${
                            poDetails.poDetails.some(
                              (p) => p.prodId === product.productId
                            ) && "d-none"
                          }`}
                          key={product.productId}
                        >
                          <input
                            type="checkbox"
                            id={product.productId.toString()}
                            checked={poDetails.poDetails.some(
                              (p) => p.prodId === product.productId
                            )} // Set checked based on presence in selectedProducts
                            onChange={() => handleCheckboxClick(product)} // Set checked based on presence in selectedProducts
                          />
                          <label htmlFor={product.productId.toString()}>
                            <b>{product.productCode}</b> - {product.productName}
                          </label>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="modal-footer modal-footer-uniform">
                  {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    data-bs-dismiss="modal"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default PurchaseDetailsComponent;
