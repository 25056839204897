import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../../common/constants/labelConstants";
import { RoutingConstants } from "../../../common/constants/routingConstants";
import { NavLink, useNavigate } from "react-router-dom";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  GenderOptions,
  NewEmployeeAddress,
  NewEmployeeAddressContact,
  Statuses,
  validateEmailRegex,
} from "../../../common/constants/generalConstants";
import { cloneDeep } from "lodash";
import {
  IEmployee,
  IEmployeeAddress,
  IEmployeeAddressContact,
  IEmployeeRole,
} from "../../../common/models";
import { EmployeeContext } from "../../../controllers/contexts/employee.context";
import validator from "../../../common/models/validator";
import _isEqual from "lodash/isEqual";
import _ from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { getDateIfValid } from "../../../common/methods/globalmethods";

const VALIDATOR = {
  empName: [(value) => (_.isEmpty(value) ? `Enter Employee Name` : null)],
  gender: [(value) => (_.isEmpty(value) ? `Enter Gender` : null)],
  dob: [(value) => (_.isEmpty(value) ? `Enter Date Of Birth` : null)],
  dateOfJoining: [
    (value) => (_.isEmpty(value) ? `Enter Date Of Joining` : null),
  ],
  username: [(value) => (_.isEmpty(value) ? `Enter UserName` : null)],
  password: [(value) => (_.isEmpty(value) ? `Enter Password` : null)],
  comments: [(value) => (_.isEmpty(value) ? `Enter Comments` : null)],
  designation: [(value) => (_.isEmpty(value) ? `Select Designation` : null)],
  passcode: [
    (value) =>
      _.isEmpty(value)
        ? `Enter  Passcode`
        : Number(value) <= 0
        ? `Enter  Passcode`
        : null,
  ],
  status: [(value) => (_.isEmpty(value) ? `Enter  Status` : null)],
};
interface FormErrors {
  empName?: string; // "penchalaswamy",
  empType?: string | null;
  gender?: string; // "Male",
  dob?: string; // "2023-12-12",
  dateOfJoining?: string; // "2023-12-21",
  dateOfResign?: string; // "2023-12-22",
  passcode?: number; // 1234,
  username?: string; //
  password?: string; //
  comments?: string | null;
  designation?: string;
  status?: string | null;
  // Add other form field errors as needed
}

const EmployeeFormComponent = () => {
  const { employeeForm, setEmployeeForm, saveEmployee, employeeRoles } =
    useContext(EmployeeContext);
  const navigate = useNavigate();
  const [error, setError] = useState<FormErrors>({});
  const [addError, setAddError] = useState(false);

  useEffect(() => {
    if (employeeForm === null) {
      navigate(RoutingConstants.employee);
    }
  }, [employeeForm, navigate]);

  const handleEmployeeForm = (type: string, value: any) => {
    const _form = cloneDeep(employeeForm);
    if(type === 'passcode') {
      if (/^\d*$/.test(value)) {
        _form[type] = value;
      }
    } else {
      _form[type] = value;
    }
    setEmployeeForm(_form);
  };

  const handleEmployeeAddressForm = (idx: number, type: string, value: any) => {
    const _form = cloneDeep(employeeForm);
    _form["empAddresses"][idx][type] = value;
    setEmployeeForm(_form);
  };

  const handleEmployeeAddressContactForm = (
    parentIdx: number,
    idx: number,
    type: string,
    value: any
  ) => {
    const _form = cloneDeep(employeeForm);
    _form["empAddresses"][parentIdx]["empCommunication"][idx][type] = value;
    setEmployeeForm(_form);
  };
  const handleEmployeeAddressContactPhone = (
    parentIdx: number,
    idx: number,
    value: any
  ) => {
    const _form: IEmployee = cloneDeep(employeeForm);
    let _destCode: string = "";
    let _phoneNum: string = "";
    if (value !== "") {
      if (value.length > 4) {
        const phoneNumberParsed = parsePhoneNumber(value);
        if (phoneNumberParsed) {
          _destCode = "+" + phoneNumberParsed.countryCallingCode;
          _phoneNum = phoneNumberParsed.nationalNumber;
        } else {
          toast.error(" Please enter a valid number.");
          return;
        }
      } else {
        _destCode = value;
        _phoneNum = "";
      }
    }
    _form["empAddresses"][parentIdx]["empCommunication"][
      idx
    ].designationCountryCode = _destCode;
    _form["empAddresses"][parentIdx]["empCommunication"][idx].phoneNumber =
      _phoneNum;
    setEmployeeForm(_form);
  };

  const handleClose = () => {
    setEmployeeForm(null);
  };
  const checkForm = () => {
    let obj = {
      empName: employeeForm?.empName,
      gender: employeeForm?.gender,
      dob: moment(employeeForm?.dob).format("DD/MM/yyyy"),
      dateOfJoining: moment(employeeForm?.dateOfJoining).format("DD/MM/yyyy"),
      passcode: employeeForm?.passcode,
      username: employeeForm?.username,
      password: employeeForm?.password,
      status: employeeForm?.status,
      comments: employeeForm?.comments,
      designation: employeeForm?.designation,
    };
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    let isValid = false;
    if (_.isEmpty(isErrors)) {
      for (
        let _addIdx = 0;
        _addIdx < employeeForm?.empAddresses.length;
        _addIdx++
      ) {
        const _add = employeeForm?.empAddresses[_addIdx];
        if (
          _add.address1 !== "" &&
          _add.address2 !== "" &&
          _add.city !== "" &&
          _add.county !== "" &&
          _add.country !== "" &&
          _add.postCode !== ""
        ) {
          for (
            let _addContIdx = 0;
            _addContIdx < _add.empCommunication.length;
            _addContIdx++
          ) {
            const _addCont = _add.empCommunication[_addContIdx];
            if (
              _addCont.contactName !== "" &&
              _addCont.designationCountryCode !== "" &&
              _addCont.phoneNumber !== "" &&
              _addCont.phoneNumber.length === 10 &&
              _addCont.email !== "" &&
              validateEmailRegex(_addCont.email)
            ) {
              saveEmployee();
            } else {
              setAddError(true);
            }
          }
        } else {
          setAddError(true);
        }
      }
    } else {
      setAddError(true);
    }
  };

  const isEmployeeValid = (): boolean => {
    let isValid = false;
    isValid =
      employeeForm?.empName !== "" &&
      employeeForm?.dateOfJoining !== "" &&
      employeeForm?.dob !== "" &&
      employeeForm?.gender !== "" &&
      employeeForm?.username !== "" &&
      employeeForm?.passcode !== "" &&
      employeeForm?.password !== "" &&
      employeeForm?.comments !== "" &&
      employeeForm?.designation !== "" &&
      employeeForm?.status !== "";

    if (isValid) {
      for (
        let _addIdx = 0;
        _addIdx < employeeForm?.empAddresses.length;
        _addIdx++
      ) {
        const _add = employeeForm?.empAddresses[_addIdx];
        isValid =
          _add.address1 !== "" &&
          _add.address2 !== "" &&
          _add.city !== "" &&
          _add.county !== "" &&
          _add.country !== "" &&
          _add.postCode !== "";

        for (
          let _addContIdx = 0;
          _addContIdx < _add.empCommunication.length;
          _addContIdx++
        ) {
          const _addCont = _add.empCommunication[_addContIdx];
          isValid =
            _addCont.contactName !== "" &&
            _addCont.designationCountryCode !== "" &&
            _addCont.phoneNumber !== "" &&
            _addCont.phoneNumber.length === 10 &&
            _addCont.email !== "" &&
            validateEmailRegex(_addCont.email);
          if (!isValid) {
            break;
          }
        }
        if (!isValid) {
          break;
        }
      }
    }

    return !isValid;
  };
  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${employeeForm?.empId ? LabelConstants.Edit : LabelConstants.Create} ${LabelConstants.Employee}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    {" "}
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.Master}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.employee}
                      title={LabelConstants.Employee}
                    >
                      {LabelConstants.Employee}
                    </NavLink>{" "}
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${
                    employeeForm?.empId
                      ? LabelConstants.Edit
                      : LabelConstants.Create
                  } ${LabelConstants.Employee}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <h4 className="box-title">{LabelConstants.EmployeeInfo}</h4>
                <div className="row">
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="supName">
                      {LabelConstants.EmployeeName}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="supName"
                      type="text"
                      className="form-control"
                      placeholder="Employee Name"
                      value={employeeForm?.empName}
                      style={error?.empName && { borderColor: "red" }}
                      onChange={(e) =>
                        handleEmployeeForm("empName", e.target.value)
                      }
                    />
                    {error?.empName && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.empName}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">
                      {LabelConstants.Gender}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={employeeForm?.gender}
                      onChange={(e) =>
                        handleEmployeeForm("gender", e.target.value)
                      }
                      style={error?.gender && { borderColor: "red" }}
                    >
                      <option value={""}>{"Select Gender"}</option>
                      {GenderOptions.map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            <option
                              key={`genderOption${_statusIdx}`}
                              value={_status}
                            >
                              {_status}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.gender && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.gender}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="dob">
                      {LabelConstants.DateofBirth}
                      <span className="text-danger">*</span>
                    </label>
                    {/* <input className="form-control"
                                        id="dob"
                                        type="date"
                                        placeholder="Date of birth"
                                        value={employeeForm?.dob}
                                        style={error?.dob && { borderColor: "red" }}
                                        onChange={(e) => handleEmployeeForm('dob', e.target.value)}
                                    /> */}
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(employeeForm?.dob)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleEmployeeForm("dob", date);
                      }}
                    />

                    {error?.dob && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.dob}</span>
                      </label>
                    )}
                  </div>

                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="dateOfJoining">
                      {LabelConstants.DateofJoining}
                      <span className="text-danger">*</span>
                    </label>
                    {/* <input className="form-control"
                                        id="dateOfJoining"
                                        type="date"
                                        placeholder="Date Of Joining"
                                        value={employeeForm?.dateOfJoining}
                                        style={error?.dateOfJoining && { borderColor: "red" }}
                                        onChange={(e) => handleEmployeeForm('dateOfJoining', e.target.value)}
                                    /> */}

                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(employeeForm?.dateOfJoining)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleEmployeeForm("dateOfJoining", date);
                      }}
                    />
                    {error?.dateOfJoining && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.dateOfJoining}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="username">
                      {LabelConstants.Username}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="username"
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={employeeForm?.username}
                      style={error?.username && { borderColor: "red" }}
                      onChange={(e) =>
                        handleEmployeeForm("username", e.target.value)
                      }
                    />
                    {error?.username && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.username}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="password">
                      {LabelConstants.Password}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={employeeForm?.password}
                      style={error?.password && { borderColor: "red" }}
                      onChange={(e) =>
                        handleEmployeeForm("password", e.target.value)
                      }
                    />
                    {error?.password && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.password}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="passcode">
                      {LabelConstants.Passcode}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      id="passcode"
                      type="password"
                      className="form-control"
                      placeholder="Passcode"
                      value={employeeForm?.passcode}
                      style={error?.passcode && { borderColor: "red" }}
                      onChange={(e) =>
                        handleEmployeeForm("passcode", e.target.value)
                      }
                    />
                    {error?.passcode && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.passcode}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label" htmlFor="dateOfResign">
                      {LabelConstants.DateofResignation}
                    </label>
                    {/* <input className="form-control"
                                        id="dateOfResign"
                                        type="date"
                                        min={employeeForm ? employeeForm?.dateOfResign : null}
                                        placeholder="Date Of Resign"
                                        value={employeeForm?.dateOfResign}
                                        style={error?.dateOfResign && { borderColor: "red" }}
                                        onChange={(e) => handleEmployeeForm('dateOfResign', e.target.value)}
                                    /> */}

                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(employeeForm?.dateOfResign)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleEmployeeForm("dateOfResign", date);
                      }}
                    />
                    {error?.dateOfResign && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.dateOfResign}
                        </span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={employeeForm?.status}
                      onChange={(e) =>
                        handleEmployeeForm("status", e.target.value)
                      }
                    >
                      {Statuses.map((_status: string, _statusIdx: number) => {
                        return (
                          <option
                            key={`statusOption${_statusIdx}`}
                            value={_status}
                          >
                            {_status}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="form-group col-8">
                    <label className="form-label" htmlFor="comments">
                      {LabelConstants.Comments}
                      <span className="text-danger">*</span>
                    </label>
                    <textarea
                      id={"comments"}
                      value={employeeForm?.comments}
                      onChange={(e) =>
                        handleEmployeeForm("comments", e.target.value)
                      }
                      rows={5}
                      cols={5}
                      className="form-control"
                      placeholder="Commnets"
                      style={error?.comments && { borderColor: "red" }}
                    />
                    {error?.comments && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>{error?.comments}</span>
                      </label>
                    )}
                  </div>
                  <div className="form-group col-4">
                    <label className="form-label">
                      {LabelConstants.Designation}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      value={employeeForm?.designation}
                      onChange={(e) =>
                        handleEmployeeForm("designation", e.target.value)
                      }
                    >
                      <option value="">--</option>
                      {employeeRoles?.map(
                        (_role: IEmployeeRole, _roleIdx: number) => {
                          return (
                            <option
                              key={`statusOption${_roleIdx}`}
                              value={_role?.roleCode}
                            >
                              {_role?.roleName}
                            </option>
                          );
                        }
                      )}
                    </select>
                    {error?.designation && (
                      <label className="form-label">
                        <span style={{ color: "red" }}>
                          {error?.designation}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <div className="d-flex justify-content-between">
                  <h4 className="box-title">
                    {LabelConstants.EmployeeAddresses}
                  </h4>
                  <button
                    type="button"
                    className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-primary me-3"
                    onClick={() => {
                      const _form: IEmployee = cloneDeep(employeeForm);
                      _form.empAddresses.push(cloneDeep(NewEmployeeAddress));
                      setEmployeeForm(_form);
                    }}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </div>
                {employeeForm?.empAddresses.map(
                  (_add: IEmployeeAddress, _addIdx: number) => {
                    return (
                      <div
                        className="box box-bordered"
                        key={`supplierAddress${_addIdx}`}
                      >
                        <div
                          className="box-header with-border"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapseExample${_addIdx}`}
                        >
                          <div className="d-flex justify-content-between">
                            <h4 className="box-title m-0">
                              Address #{_addIdx + 1}
                            </h4>
                            {employeeForm?.empAddresses.length > 1 && (
                              <button
                                type="button"
                                className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-danger me-3"
                                onClick={() => {
                                  const _form: IEmployee =
                                    cloneDeep(employeeForm);
                                  _form.empAddresses.splice(_addIdx, 1);
                                  setEmployeeForm(_form);
                                }}
                              >
                                <i
                                  className="fa fa-trash"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          </div>
                        </div>
                        <div
                          className="box-body collapse show"
                          id={`collapseExample${_addIdx}`}
                        >
                          <div className="row">
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.Address1}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 1"
                                value={_add?.address1}
                                onChange={(e) =>
                                  handleEmployeeAddressForm(
                                    _addIdx,
                                    "address1",
                                    e.target.value
                                  )
                                }
                                style={error?.empName && { borderColor: "red" }}
                              />
                              {addError && _.isEmpty(_add.address1) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter Address1"}
                                  </span>
                                </label>
                              )}
                              {_add.address1.length > 100 && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {
                                      "Address1 must be less than 100 characters"
                                    }
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.Address2}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address 2"
                                value={_add?.address2}
                                onChange={(e) =>
                                  handleEmployeeAddressForm(
                                    _addIdx,
                                    "address2",
                                    e.target.value
                                  )
                                }
                                style={error?.empName && { borderColor: "red" }}
                              />
                              {addError && _.isEmpty(_add.address2) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter Address2"}
                                  </span>
                                </label>
                              )}
                              {_add.address2.length > 100 && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {
                                      "Address1 must be less than 100 characters"
                                    }
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.City}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                value={_add?.city}
                                onChange={(e) =>
                                  handleEmployeeAddressForm(
                                    _addIdx,
                                    "city",
                                    e.target.value
                                  )
                                }
                                style={error?.empName && { borderColor: "red" }}
                              />
                              {addError && _.isEmpty(_add.city) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter City"}
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.County}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="County"
                                value={_add?.county}
                                onChange={(e) =>
                                  handleEmployeeAddressForm(
                                    _addIdx,
                                    "county",
                                    e.target.value
                                  )
                                }
                                style={error?.empName && { borderColor: "red" }}
                              />
                              {addError && _.isEmpty(_add.county) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter County"}
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.Country}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Country"
                                value={_add?.country}
                                onChange={(e) =>
                                  handleEmployeeAddressForm(
                                    _addIdx,
                                    "country",
                                    e.target.value
                                  )
                                }
                                style={error?.empName && { borderColor: "red" }}
                              />
                              {addError && _.isEmpty(_add.country) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter Country"}
                                  </span>
                                </label>
                              )}
                            </div>
                            <div className="form-group col-6">
                              <label className="form-label">
                                {LabelConstants.PostCode}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Post Code"
                                value={_add?.postCode}
                                onChange={(e) =>
                                  handleEmployeeAddressForm(
                                    _addIdx,
                                    "postCode",
                                    e.target.value
                                  )
                                }
                                style={error?.empName && { borderColor: "red" }}
                              />
                              {addError && _.isEmpty(_add.postCode) && (
                                <label className="form-label">
                                  <span style={{ color: "red" }}>
                                    {"Enter PostCode"}
                                  </span>
                                </label>
                              )}
                            </div>
                          </div>
                          <div className="d-flex justify-content-between">
                            <h4 className="box-title">
                              {LabelConstants.CommunicationDetails}
                            </h4>
                            <button
                              type="button"
                              className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-primary me-3"
                              onClick={() => {
                                const _form: IEmployee =
                                  cloneDeep(employeeForm);
                                _form.empAddresses[
                                  _addIdx
                                ].empCommunication.push(
                                  cloneDeep(NewEmployeeAddressContact)
                                );
                                setEmployeeForm(_form);
                              }}
                            >
                              <i className="fa fa-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                          {_add.empCommunication.map(
                            (
                              _addCont: IEmployeeAddressContact,
                              _addContIdx: number
                            ) => {
                              return (
                                <div
                                  key={`supplierAddressContact${_addIdx}${_addContIdx}`}
                                >
                                  <div className="d-flex justify-content-between">
                                    <h5 className="box-title m-0">
                                      Contact #{_addContIdx + 1}
                                    </h5>
                                    {_add.empCommunication.length > 1 && (
                                      <button
                                        type="button"
                                        className="waves-effect waves-circle btn btn-outline btn-circle btn-xs btn-danger me-3"
                                        onClick={() => {
                                          const _form: IEmployee =
                                            cloneDeep(employeeForm);
                                          _form.empAddresses[
                                            _addIdx
                                          ].empCommunication.splice(
                                            _addContIdx,
                                            1
                                          );
                                          setEmployeeForm(_form);
                                        }}
                                      >
                                        <i
                                          className="fa fa-trash"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    )}
                                  </div>
                                  <div className="row">
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {LabelConstants.ContactName}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Name"
                                          value={_addCont.contactName}
                                          onChange={(e) => {
                                            const textOnly =
                                              e.target.value.replace(
                                                /[0-9]/g,
                                                ""
                                              ); // Remove numbers from input
                                            handleEmployeeAddressContactForm(
                                              _addIdx,
                                              _addContIdx,
                                              "contactName",
                                              textOnly
                                            );
                                          }}
                                        />
                                        {addError &&
                                          _.isEmpty(_addCont.contactName) && (
                                            <label className="form-label">
                                              <span style={{ color: "red" }}>
                                                {"Enter Contact Name"}
                                              </span>
                                            </label>
                                          )}
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {LabelConstants.Phone}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <PhoneInput
                                          className={`form-control`}
                                          defaultCountry="GB"
                                          value={`${_addCont.designationCountryCode}${_addCont.phoneNumber}`}
                                          onChange={(e) => {
                                            console.log(e);
                                            handleEmployeeAddressContactPhone(
                                              _addIdx,
                                              _addContIdx,
                                              e ?? ""
                                            );
                                          }}
                                          style={
                                            error?.empType && {
                                              borderColor: "red",
                                            }
                                          }
                                        />
                                        {addError &&
                                        _.isEmpty(_addCont.phoneNumber) ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Phone Number"}
                                            </span>
                                          </label>
                                        ) : addError &&
                                          _addCont.phoneNumber.length !== 10 ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Proper phone Number"}
                                            </span>
                                          </label>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="form-group">
                                        <label className="form-label">
                                          {LabelConstants.Email}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          placeholder="Email"
                                          value={_addCont.email ?? ""}
                                          onChange={(e) =>
                                            handleEmployeeAddressContactForm(
                                              _addIdx,
                                              _addContIdx,
                                              "email",
                                              e.target.value
                                            )
                                          }
                                          style={
                                            error?.empName && {
                                              borderColor: "red",
                                            }
                                          }
                                        />
                                        {addError &&
                                        _.isEmpty(_addCont.email) ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Email"}
                                            </span>
                                          </label>
                                        ) : addError &&
                                          !validateEmailRegex(
                                            _addCont.email
                                          ) ? (
                                          <label className="form-label">
                                            <span style={{ color: "red" }}>
                                              {"Enter Proper Email"}
                                            </span>
                                          </label>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="box">
              <div className="box-body">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => handleClose()}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary float-end"
                  onClick={() => checkForm()}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeFormComponent;
