import { useContext, useState } from "react";
import { DashboardContext } from "../../controllers/contexts/dashboard.context";
import Select from "react-select";
import { TimePeriodValues } from "../../common/constants/generalConstants";
import {
  getCustomerName,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import {
  IAislesLocationsUsage,
  IBestSellerData,
  IShippingDetails,
  ITodayOrdersShippingDetails,
} from "../../common/models/dashboard";
import moment from "moment";
import { LabelConstants } from "../../common/constants/labelConstants";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const DashboardComponent = () => {
  const {
    timePeriod,
    settimePeriod,
    warehouses,
    warehouseId,
    setwarehouseId,
    revenueAndUnits,
    bestSellersData,
    todaysOrderShippingDetails,
    shelvesOveview,
    ailesLocationUsage,
    customerList,
  } = useContext(DashboardContext);

  const getIndicatorWidth = (condition, value) => {
    let result = value;
    if (isNullUndefined(condition) || value < 0) return 0;
    if (value > 100) return 100;
    return result;
  };

  const getAisleIndicatorColor = (value) => {
    let result = "";
    if (value <= 0) {
      result = "bg-ash";
    } else if (value <= 20) {
      result = "bg-primary";
    } else if (value <= 40) {
      result = "bg-success";
    } else if (value <= 60) {
      result = "bg-warning";
    } else if (value <= 80) {
      result = "bg-danger";
    } else {
      result = "bg-red";
    }

    return result;
  };

  return (
    <div className="container-full">
      <section className="content">
        <div className="row">
          <div className="col-xl-3 col-12">
            <div className="box box-body">
              <h6>
                <span className="text-uppercase fs-18">Revenue</span>
              </h6>
              <br />
              <p className="fs-26">
                {" "}
                {LabelConstants.DollarSign}
                {revenueAndUnits?.revenue?.toFixed(2) ?? "-"}
              </p>
              <div className="progress progress-xxs mt-0 mb-10">
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  style={{
                    width: `${getIndicatorWidth(
                      revenueAndUnits?.isPercentageOfRevenueIncreased,
                      revenueAndUnits?.percentageComparisonOfRevenue
                    )}%`,
                    height: 4,
                  }}
                  aria-valuenow={35}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <div className="fs-12">
                {!isNullUndefined(
                  revenueAndUnits?.percentageComparisonOfRevenue
                ) ? (
                  <>
                    <i
                      className={`${
                        revenueAndUnits?.isPercentageOfRevenueIncreased
                          ? "ion-arrow-graph-up-right"
                          : "ion-arrow-graph-down-right"
                      } text-primary me-1`}
                    />
                    {" %"}
                    {revenueAndUnits?.percentageComparisonOfRevenue}{" "}
                    {revenueAndUnits?.isPercentageOfRevenueIncreased
                      ? "increase"
                      : "decrease"}{" "}
                    {timePeriod === 1 ? "in" : "from"}{" "}
                    {
                      TimePeriodValues?.find(
                        (item) => item.value === timePeriod
                      )?.label
                    }
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box box-body">
              <h6>
                <span className="text-uppercase fs-18">Units Sold</span>
              </h6>
              <br />
              <p className="fs-26">{revenueAndUnits?.unitsSold ?? "-"}</p>
              <div className="progress progress-xxs mt-0 mb-10">
                <div
                  className="progress-bar bg-danger"
                  role="progressbar"
                  style={{
                    width: `${getIndicatorWidth(
                      revenueAndUnits?.isPercentageOfUnitsSoldIncreased,
                      revenueAndUnits?.percentageComparisonOfUnitsSold
                    )}%`,
                    height: 4,
                  }}
                  aria-valuenow={35}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <div className="fs-12">
                {!isNullUndefined(
                  revenueAndUnits?.percentageComparisonOfUnitsSold
                ) ? (
                  <>
                    <i
                      className={`${
                        revenueAndUnits?.isPercentageOfUnitsSoldIncreased
                          ? "ion-arrow-graph-up-right"
                          : "ion-arrow-graph-down-right"
                      } text-primary me-1`}
                    />
                    {" %"}
                    {revenueAndUnits?.percentageComparisonOfUnitsSold}{" "}
                    {revenueAndUnits?.isPercentageOfUnitsSoldIncreased
                      ? "up"
                      : "down"}
                  </>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box box-body">
              <h6>
                <span className="text-uppercase fs-18">Shelfs Overview</span>
              </h6>
              <br />
              <p className="fs-26">{shelvesOveview?.loadedShelves}</p>
              <div className="progress progress-xxs mt-0 mb-10">
                <div
                  className="progress-bar bg-info"
                  role="progressbar"
                  style={{
                    width: `${getIndicatorWidth(
                      shelvesOveview?.percentageOfLoadedShelves,
                      shelvesOveview?.percentageOfLoadedShelves
                    )}%`,
                    height: 4,
                  }}
                  aria-valuenow={35}
                  aria-valuemin={0}
                  aria-valuemax={100}
                />
              </div>
              <div className="flexbox fs-12">
                <span>
                  <i
                    className={`${
                      shelvesOveview?.percentageOfLoadedShelves > 0
                        ? "ion-arrow-graph-up-right"
                        : "ion-arrow-graph-down-right"
                    } text-info me-1`}
                  />
                  {" %"}
                  {shelvesOveview?.percentageOfLoadedShelves}
                </span>
                <span>{shelvesOveview?.totalShelves}</span>
              </div>
            </div>
          </div>
          <div className="col-xl-3 col-12">
            <div className="box box-body">
              <h6>
                <span className="text-uppercase fs-18">Time Period</span>
              </h6>
              <br />
              <br />
              <Select
                styles={{
                  singleValue: (provided) => ({
                    ...provided,
                    color: "white",
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: "#4170f6",
                    borderColor: "#4170f6",
                  }),
                }}
                value={TimePeriodValues?.filter(
                  (option: any) => option.value === timePeriod
                )}
                placeholder="Time Period"
                options={TimePeriodValues}
                onChange={(e: any) => {
                  settimePeriod(e.value);
                }}
              />
              <br />
            </div>
            {/* <div className="box">
              <div className="box-body text-center">
                <br />
                <br />
                <p
                  className="mb-20 text-fade text-end"
                  style={{ paddingBottom: 1 }}
                >
                  Time Period
                </p>
                <div className="btn-group w-p100">
                  <button
                    className="btn btn-sm btn-primary-light dropdown-toggle show"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Last One Month
                  </button>
                  <div
                    style={{
                      position: "absolute",
                      inset: "0px auto auto 0px",
                      margin: 0,
                      transform: "translate3d(0px, 32px, 0px)",
                    }}
                    data-popper-placement="bottom-start"
                  >
                    <a className="dropdown-item" href="#">
                      Three Month
                    </a>
                    <a className="dropdown-item" href="#">
                      Six Month
                    </a>
                    <a className="dropdown-item" href="#">
                      Last One Year
                    </a>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div> */}
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-xl-6 col-12">
              <div className="box h-550">
                <div
                  className="box-header with-border"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 14,
                    paddingLeft: 20,
                  }}
                >
                  <h4 className="col-8 box-title">Usage of Aisle</h4>
                  <div className="col-4">
                    <Select
                      value={warehouses?.filter(
                        (option: any) => option.value === warehouseId
                      )}
                      placeholder="Warehouse"
                      options={warehouses}
                      isSearchable={true}
                      onChange={(e: any) => {
                        setwarehouseId(e.value);
                      }}
                    />
                  </div>
                </div>

                <div className="box-body pt-0 overflow-auto">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <tbody>
                        {ailesLocationUsage?.map(
                          (ailesLocation: IAislesLocationsUsage) => (
                            <tr>
                              <td>
                                <a href="javascript:void(0)">
                                  Aisle {ailesLocation?.aisleName}
                                </a>
                              </td>
                              <td>
                                <span className="text-muted text-nowrap">
                                  <i className="fa fa-calendar-o text-success-light mx-5"></i>{" "}
                                  {moment(new Date()).format("DD/MM/yyyy")}
                                </span>{" "}
                              </td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <span className="mx-5">Used</span>
                                  <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={
                                      <Popover id="popover-basic">
                                        <Popover.Header as="h3">
                                          <strong>
                                            {"Used - " +
                                              ailesLocation?.totalUsedLocations}
                                          </strong>
                                        </Popover.Header>
                                        <Popover.Body>
                                          <strong>
                                            {"Total - " +
                                              ailesLocation?.totalLocations}
                                          </strong>
                                        </Popover.Body>
                                      </Popover>
                                    }
                                  >
                                    <div className="progress progress-xs w-p100 mt-0">
                                      <div
                                        className={`progress-bar ${getAisleIndicatorColor(
                                          ailesLocation?.percentageOfUsedLocations
                                        )}`}
                                        role="progressbar"
                                        style={{
                                          width: `${getIndicatorWidth(
                                            ailesLocation?.percentageOfUsedLocations,
                                            ailesLocation?.percentageOfUsedLocations
                                          )}%`,
                                        }}
                                        aria-valuenow={50}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </OverlayTrigger>
                                </div>
                              </td>
                              <td className="text-end">
                                {ailesLocation?.percentageOfUsedLocations}%
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-12">
              <div className="box h-550">
                <div className="box-header">
                  <h4 className="box-title">Top 5 Best Sellers Stock</h4>
                </div>
                <div
                  className="box-body overflow-auto"
                  // style={{ maxHeight: 300 }}
                >
                  <div className="inner-user-div3">
                    {bestSellersData?.map((item: IBestSellerData) => (
                      <div className="box-shadowed p-10 mb-5 rounded10">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h5 className="my-5">
                              {getCustomerName(item?.customerId, customerList)}
                            </h5>
                            <p className="mb-0">#{item.customerId}</p>
                          </div>
                          <div>
                            <h3 className="my-5 text-end">
                              {item?.totalUnitsSold} Units
                            </h3>
                            <p className="mb-0">
                              <span>
                                {LabelConstants.DollarSign}{" "}
                                {item?.avgPricePerUnit?.toFixed(2)} per unit
                              </span>{" "}
                              |{" "}
                              <strong>
                                {LabelConstants.DollarSign}
                                {item?.totalUnitsAmount?.toFixed(2)}
                              </strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6 col-12">
              <div className="box h-400">
                <div className="box-header with-border">
                  <h4 className="box-title">Orders Shipping Today</h4>
                  <div className="box-controls pull-right">
                    <div className="lookup lookup-circle lookup-right">
                      <input type="text" name="s" />
                    </div>
                  </div>
                </div>
                <div className="box-body p-0">
                  <div
                    className="table-responsive overflow-auto"
                    style={{ maxHeight: 300 }}
                  >
                    <table className="table mb-0 dash-table">
                      <tbody>
                        {todaysOrderShippingDetails?.shippingDetails?.map(
                          (item: IShippingDetails) => (
                            <tr>
                              <td>
                                <a href="javascript:void(0)">{item.soNum}</a>
                              </td>
                              <td>
                                <span className="text-muted text-nowrap">
                                  {!isNullUndefined(item?.shippingDate) &&
                                  item.shippingDate !== ""
                                    ? moment(item.shippingDate).format(
                                        "DD/MM/yyyy"
                                      )
                                    : ""}
                                </span>{" "}
                              </td>
                              <td>{item.custName}</td>
                              <td>
                                <span className="badge badge-pill badge-danger">
                                  Shipping
                                </span>
                              </td>
                              <td>
                                {LabelConstants.DollarSign}
                                {item.amount}
                              </td>
                              <td>
                                {item?.isDispatched ? (
                                  <i className="fa fa-truck" />
                                ) : null}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-12">
              <div
                className="box  h-400"
                style={{ backgroundColor: "#2e72ff" }}
              >
                <div className="box-header">
                  <h4 className="box-title text-white">Statistics</h4>
                </div>
                <div className="box-body" style={{ maxHeight: 300 }}>
                  <div className="d-flex justify-content-evenly">
                    <div
                      id="progressbar1"
                      className="w-100 text-center position-relative"
                    >
                      <span className="text-white">
                        {todaysOrderShippingDetails?.totalOrders}
                        <br />
                        {LabelConstants.DollarSign}
                        {todaysOrderShippingDetails?.totalOrdersAmount}
                      </span>
                      <svg
                        viewBox="0 0 100 100"
                        style={{ display: "block", width: "100%" }}
                      >
                        <path
                          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
                          stroke="#6b9dfc"
                          strokeWidth={5}
                          fillOpacity={0}
                        />
                        <path
                          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
                          stroke="rgb(255,255,255)"
                          strokeWidth={5}
                          fillOpacity={0}
                          style={{
                            strokeDasharray: "282.783, 282.783",
                            strokeDashoffset: "0",
                          }}
                        />
                      </svg>
                      <div
                        className="progressbar-text"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          padding: 0,
                          margin: 0,
                          transform: "translate(-50%, -50%)",
                          color: "rgb(255, 255, 255)",
                          fontFamily: "Raleway, Helvetica, sans-serif",
                          fontSize: "2rem",
                        }}
                      >
                        117
                      </div>
                    </div>
                    <div
                      id="progressbar2"
                      className="w-100 text-center position-relative"
                    >
                      <span className="text-white">
                        Orders Sent
                        <br />
                        {todaysOrderShippingDetails?.ordersSent}
                      </span>
                      <svg
                        viewBox="0 0 100 100"
                        style={{ display: "block", width: "100%" }}
                      >
                        <path
                          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
                          stroke="#6b9dfc"
                          strokeWidth={5}
                          fillOpacity={0}
                        />
                        <path
                          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
                          stroke="rgb(255,255,255)"
                          strokeWidth={5}
                          fillOpacity={0}
                          style={{
                            strokeDasharray: "282.783, 282.783",
                            strokeDashoffset: "0",
                          }}
                        />
                      </svg>
                      <div
                        className="progressbar-text"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          padding: 0,
                          margin: 0,
                          transform: "translate(-50%, -50%)",
                          color: "rgb(255, 255, 255)",
                          fontFamily: "Raleway, Helvetica, sans-serif",
                          fontSize: "2rem",
                        }}
                      >
                        90
                      </div>
                    </div>
                    <div
                      id="progressbar3"
                      className="w-100 text-center position-relative"
                    >
                      <span className="text-white">
                        Orders picked
                        <br />
                        {todaysOrderShippingDetails?.orderPicked}
                      </span>
                      <svg
                        viewBox="0 0 100 100"
                        style={{ display: "block", width: "100%" }}
                      >
                        <path
                          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
                          stroke="#6b9dfc"
                          strokeWidth={5}
                          fillOpacity={0}
                        />
                        <path
                          d="M 50,50 m 0,-45 a 45,45 0 1 1 0,90 a 45,45 0 1 1 0,-90"
                          stroke="rgb(255,255,255)"
                          strokeWidth={5}
                          fillOpacity={0}
                          style={{
                            strokeDasharray: "282.783, 282.783",
                            strokeDashoffset: "0",
                          }}
                        />
                      </svg>
                      <div
                        className="progressbar-text"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          padding: 0,
                          margin: 0,
                          transform: "translate(-50%, -50%)",
                          color: "rgb(255, 255, 255)",
                          fontFamily: "Raleway, Helvetica, sans-serif",
                          fontSize: "2rem",
                        }}
                      >
                        60
                      </div>
                    </div>
                  </div>
                  {/*Vertical lines start*/}
                  <div className="mt-50 d-flex justify-content-between align-items-end">
                    <div style={{ position: "relative" }}>
                      <div id="apexChart2" style={{ minHeight: 73 }}>
                        <div
                          id="apexchartsdlc3dge"
                          className="apexcharts-canvas apexchartsdlc3dge apexcharts-theme-light"
                          style={{ width: 100, height: 73 }}
                        >
                          <svg
                            id="SvgjsSvg5552"
                            width={100}
                            height={73}
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            className="apexcharts-svg"
                            transform="translate(0, 0)"
                            style={{ background: "transparent" }}
                          >
                            <g
                              id="SvgjsG5554"
                              className="apexcharts-inner apexcharts-graphical"
                              transform="translate(10.904761904761905, 0)"
                            >
                              <defs id="SvgjsDefs5553">
                                <linearGradient
                                  id="SvgjsLinearGradient5557"
                                  x1={0}
                                  y1={0}
                                  x2={0}
                                  y2={1}
                                >
                                  <stop
                                    id="SvgjsStop5558"
                                    stopOpacity="0.4"
                                    stopColor="rgba(216,227,240,0.4)"
                                    offset={0}
                                  />
                                  <stop
                                    id="SvgjsStop5559"
                                    stopOpacity="0.5"
                                    stopColor="rgba(190,209,230,0.5)"
                                    offset={1}
                                  />
                                  <stop
                                    id="SvgjsStop5560"
                                    stopOpacity="0.5"
                                    stopColor="rgba(190,209,230,0.5)"
                                    offset={1}
                                  />
                                </linearGradient>
                                <clipPath id="gridRectMaskdlc3dge">
                                  <rect
                                    id="SvgjsRect5562"
                                    width="103.99999999999999"
                                    height={73}
                                    x="-8.904761904761905"
                                    y={0}
                                    rx={0}
                                    ry={0}
                                    opacity={1}
                                    strokeWidth={0}
                                    stroke="none"
                                    strokeDasharray={0}
                                    fill="#fff"
                                  />
                                </clipPath>
                                <clipPath id="gridRectMarkerMaskdlc3dge">
                                  <rect
                                    id="SvgjsRect5563"
                                    width="90.19047619047619"
                                    height={77}
                                    x={-2}
                                    y={-2}
                                    rx={0}
                                    ry={0}
                                    opacity={1}
                                    strokeWidth={0}
                                    stroke="none"
                                    strokeDasharray={0}
                                    fill="#fff"
                                  />
                                </clipPath>
                              </defs>
                              {/* <rect
                                id="SvgjsRect5561"
                                width={5}
                                height={73}
                                x="83.26870727539062"
                                y={0}
                                rx={0}
                                ry={0}
                                opacity={1}
                                strokeWidth={0}
                                strokeDasharray={3}
                                fill="url(#SvgjsLinearGradient5557)"
                                className="apexcharts-xcrosshairs"
                                y2={73}
                                filter="none"
                                fillOpacity="0.9"
                                x1="83.26870727539062"
                                x2="83.26870727539062"
                              /> */}
                              <g
                                id="SvgjsG5575"
                                className="apexcharts-xaxis"
                                transform="translate(0, 0)"
                              >
                                <g
                                  id="SvgjsG5576"
                                  className="apexcharts-xaxis-texts-g"
                                  transform="translate(0, -4)"
                                />
                              </g>
                              <g id="SvgjsG5584" className="apexcharts-grid">
                                <g
                                  id="SvgjsG5585"
                                  className="apexcharts-gridlines-horizontal"
                                  style={{ display: "none" }}
                                >
                                  <line
                                    id="SvgjsLine5587"
                                    x1="-6.904761904761904"
                                    y1={0}
                                    x2="93.09523809523809"
                                    y2={0}
                                    stroke="#e0e0e0"
                                    strokeDasharray={0}
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine5588"
                                    x1="-6.904761904761904"
                                    y1="14.6"
                                    x2="93.09523809523809"
                                    y2="14.6"
                                    stroke="#e0e0e0"
                                    strokeDasharray={0}
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine5589"
                                    x1="-6.904761904761904"
                                    y1="29.2"
                                    x2="93.09523809523809"
                                    y2="29.2"
                                    stroke="#e0e0e0"
                                    strokeDasharray={0}
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine5590"
                                    x1="-6.904761904761904"
                                    y1="43.8"
                                    x2="93.09523809523809"
                                    y2="43.8"
                                    stroke="#e0e0e0"
                                    strokeDasharray={0}
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine5591"
                                    x1="-6.904761904761904"
                                    y1="58.4"
                                    x2="93.09523809523809"
                                    y2="58.4"
                                    stroke="#e0e0e0"
                                    strokeDasharray={0}
                                    className="apexcharts-gridline"
                                  />
                                  <line
                                    id="SvgjsLine5592"
                                    x1="-6.904761904761904"
                                    y1={73}
                                    x2="93.09523809523809"
                                    y2={73}
                                    stroke="#e0e0e0"
                                    strokeDasharray={0}
                                    className="apexcharts-gridline"
                                  />
                                </g>
                                <g
                                  id="SvgjsG5586"
                                  className="apexcharts-gridlines-vertical"
                                  style={{ display: "none" }}
                                />
                                <line
                                  id="SvgjsLine5594"
                                  x1={0}
                                  y1={73}
                                  x2="86.19047619047619"
                                  y2={73}
                                  stroke="transparent"
                                  strokeDasharray={0}
                                />
                                <line
                                  id="SvgjsLine5593"
                                  x1={0}
                                  y1={1}
                                  x2={0}
                                  y2={73}
                                  stroke="transparent"
                                  strokeDasharray={0}
                                />
                              </g>
                              <g
                                id="SvgjsG5564"
                                className="apexcharts-bar-series apexcharts-plot-series"
                              >
                                <g
                                  id="SvgjsG5565"
                                  className="apexcharts-series"
                                >
                                  <path
                                    id="SvgjsPath5567"
                                    d="M -1.2312925170068028 73L -1.2312925170068028 46.72Q -1.2312925170068028 46.72 -1.2312925170068028 46.72L 1.2312925170068028 46.72Q 1.2312925170068028 46.72 1.2312925170068028 46.72L 1.2312925170068028 46.72L 1.2312925170068028 73L 1.2312925170068028 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="46.72"
                                    cx="1.2312925170068028"
                                  />
                                  <path
                                    id="SvgjsPath5568"
                                    d="M 11.081632653061224 73L 11.081632653061224 16.79Q 11.081632653061224 16.79 11.081632653061224 16.79L 13.544217687074829 16.79Q 13.544217687074829 16.79 13.544217687074829 16.79L 13.544217687074829 16.79L 13.544217687074829 73L 13.544217687074829 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="16.79"
                                    cx="13.544217687074829"
                                  />
                                  <path
                                    id="SvgjsPath5569"
                                    d="M 23.39455782312925 73L 23.39455782312925 35.04Q 23.39455782312925 35.04 23.39455782312925 35.04L 25.857142857142858 35.04Q 25.857142857142858 35.04 25.857142857142858 35.04L 25.857142857142858 35.04L 25.857142857142858 73L 25.857142857142858 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="35.04"
                                    cx="25.857142857142858"
                                  />
                                  <path
                                    id="SvgjsPath5570"
                                    d="M 35.70748299319728 73L 35.70748299319728 7.299999999999997Q 35.70748299319728 7.299999999999997 35.70748299319728 7.299999999999997L 38.17006802721089 7.299999999999997Q 38.17006802721089 7.299999999999997 38.17006802721089 7.299999999999997L 38.17006802721089 7.299999999999997L 38.17006802721089 73L 38.17006802721089 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="7.299999999999997"
                                    cx="38.17006802721089"
                                  />
                                  <path
                                    id="SvgjsPath5571"
                                    d="M 48.02040816326531 73L 48.02040816326531 18.979999999999997Q 48.02040816326531 18.979999999999997 48.02040816326531 18.979999999999997L 50.482993197278915 18.979999999999997Q 50.482993197278915 18.979999999999997 50.482993197278915 18.979999999999997L 50.482993197278915 18.979999999999997L 50.482993197278915 73L 50.482993197278915 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="18.979999999999997"
                                    cx="50.482993197278915"
                                  />
                                  <path
                                    id="SvgjsPath5572"
                                    d="M 60.33333333333334 73L 60.33333333333334 47.45Q 60.33333333333334 47.45 60.33333333333334 47.45L 62.79591836734695 47.45Q 62.79591836734695 47.45 62.79591836734695 47.45L 62.79591836734695 47.45L 62.79591836734695 73L 62.79591836734695 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="47.45"
                                    cx="62.79591836734695"
                                  />
                                  <path
                                    id="SvgjsPath5573"
                                    d="M 72.64625850340136 73L 72.64625850340136 32.849999999999994Q 72.64625850340136 32.849999999999994 72.64625850340136 32.849999999999994L 75.10884353741496 32.849999999999994Q 75.10884353741496 32.849999999999994 75.10884353741496 32.849999999999994L 75.10884353741496 32.849999999999994L 75.10884353741496 73L 75.10884353741496 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="32.849999999999994"
                                    cx="75.10884353741496"
                                  />
                                  <path
                                    id="SvgjsPath5574"
                                    d="M 84.95918367346938 73L 84.95918367346938 56.209999999999994Q 84.95918367346938 56.209999999999994 84.95918367346938 56.209999999999994L 87.42176870748298 56.209999999999994Q 87.42176870748298 56.209999999999994 87.42176870748298 56.209999999999994L 87.42176870748298 56.209999999999994L 87.42176870748298 73L 87.42176870748298 73z"
                                    fill="rgba(255,255,255,0.85)"
                                    fillOpacity={1}
                                    strokeOpacity={1}
                                    strokeLinecap="round"
                                    strokeWidth={0}
                                    strokeDasharray={0}
                                    className="apexcharts-bar-area"
                                    clipPath="url(#gridRectMaskdlc3dge)"
                                    cy="56.209999999999994"
                                    cx="87.42176870748298"
                                  />
                                </g>
                                <g
                                  id="SvgjsG5566"
                                  className="apexcharts-datalabels"
                                />
                              </g>
                              {/* <line
                                id="SvgjsLine5595"
                                x1="-6.904761904761904"
                                y1={0}
                                x2="93.09523809523809"
                                y2={0}
                                stroke="#b6b6b6"
                                strokeDasharray={0}
                                strokeWidth={1}
                                className="apexcharts-ycrosshairs"
                              />
                              <line
                                id="SvgjsLine5596"
                                x1="-6.904761904761904"
                                y1={0}
                                x2="93.09523809523809"
                                y2={0}
                                strokeDasharray={0}
                                strokeWidth={0}
                                className="apexcharts-ycrosshairs-hidden"
                              /> */}
                              <g
                                id="SvgjsG5597"
                                className="apexcharts-yaxis-annotations"
                              />
                              <g
                                id="SvgjsG5598"
                                className="apexcharts-xaxis-annotations"
                              />
                              <g
                                id="SvgjsG5599"
                                className="apexcharts-point-annotations"
                              />
                            </g>
                            <g
                              id="SvgjsG5583"
                              className="apexcharts-yaxis"
                              transform="translate(-18, 0)"
                            />
                            <g
                              id="SvgjsG5555"
                              className="apexcharts-annotations"
                            />
                          </svg>
                          <div
                            className="apexcharts-legend"
                            style={{ maxHeight: "36.5px" }}
                          />
                          <div
                            className="apexcharts-tooltip apexcharts-theme-light"
                            style={{ left: "56.3219px", top: 38 }}
                          >
                            <div
                              className="apexcharts-tooltip-series-group apexcharts-active"
                              style={{ order: 1, display: "flex" }}
                            >
                              <span
                                className="apexcharts-tooltip-marker"
                                style={{
                                  backgroundColor: "rgba(255, 255, 255, 0.85)",
                                  display: "none",
                                }}
                              />
                              <div
                                className="apexcharts-tooltip-text"
                                style={{
                                  fontFamily: "Helvetica, Arial, sans-serif",
                                  fontSize: 12,
                                }}
                              >
                                <div className="apexcharts-tooltip-y-group">
                                  <span className="apexcharts-tooltip-text-label" />
                                  {/* <span className="apexcharts-tooltip-text-value">
                                    23
                                  </span> */}
                                </div>
                                <div className="apexcharts-tooltip-z-group">
                                  <span className="apexcharts-tooltip-text-z-label" />
                                  <span className="apexcharts-tooltip-text-z-value" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="apexcharts-yaxistooltip apexcharts-yaxistooltip-0 apexcharts-yaxistooltip-left apexcharts-theme-light">
                            <div className="apexcharts-yaxistooltip-text" />
                          </div>
                        </div>
                      </div>
                      <div className="resize-triggers">
                        <div className="expand-trigger">
                          <div style={{ width: 101, height: 74 }} />
                        </div>
                        <div className="contract-trigger" />
                      </div>
                    </div>
                    {/* <div>
                      <button
                        type="button"
                        className="waves-effect waves-light btn btn-rounded btn-warning-light text-dark"
                      >
                        <i className="fa fa-ellipsis-h me-15" /> View More
                      </button>
                    </div> */}
                  </div>
                  {/*Vertical lines end*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashboardComponent;
