import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/consult_veda_logo.png";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { PickingOrder } from "../../common/models/pickingorder";
import { useContext, useEffect, useState } from "react";
import {
  getProductCatNameById,
  getProductCode,
  getProductName,
  getProductNameById,
  isNullUndefined,
  pagePrintPorStyle,
} from "../../common/methods/globalmethods";
import { OrderPickingContext } from "../../controllers/contexts/orderpicking.context";
import moment from "moment";
import { ICustomer, ICustomerAddress } from "../../common/models";
import BoxItemWithKeyValue from "./Components/BoxItemWithKeyValue";
import PrintHeader from "./PrintHeader";
import { ISalesOrder } from "../../common/models/salesorder";

const PickingPrintFormat = () => {
  const { getSalesOrderBySoNum, productsList, customersList } =
    useContext(OrderPickingContext);

  const location = useLocation();
  const [pickingItem, setPickingItem] = useState<any>(
    location?.state?.selectedItem
  );
  console.log("Picking Item - ", pickingItem);
  const [customerAddress, setcustomerAddress] =
    useState<ICustomerAddress>(null);

  useEffect(() => {
    getCustomerAddress();
  }, []);

  const getCustomerAddress = async () => {
    await getSalesOrderBySoNum(pickingItem?.soNum).then(
      (soItem: ISalesOrder[]) => {
        if (!isNullUndefined(soItem) && soItem?.length > 0) {
          let customer = customersList?.filter(
            (item) => item.custId === pickingItem?.customerId
          );
          if (customer?.length > 0) {
            customer[0]?.custAddresses?.map((address) => {
              if (
                address?.custAddressId?.toString() ===
                soItem[0]?.addressId?.toString()
              ) {
                setcustomerAddress(address);
              }
            });
          }
        }
      }
    );
  };

  return (
    <div>
      <div className="content-header row">
        <div className="d-flex align-items-center col-md-11">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.orderPickingList}
                    >
                      {LabelConstants.OrderOut}
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.orderPickingList}
                    >
                      Order Picking
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Print
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="col-md-1">
          <button
            type="button"
            className="waves-effect waves-light btn btn-success rounded"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
        </div>
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <style>{pagePrintPorStyle}</style>
        <div style={{ width: 210 + "mm" }}>
          <div
            className="col-12 pt-0 pb-3 px-30"
            style={{ backgroundColor: "white" }}
          >
            <PrintHeader centerText="PICK LIST" />
            <div className="row">
              <div className="col-7 mt-1">
                {BoxItemWithKeyValue("Pick Order#", pickingItem?.pickingNum)}
                {BoxItemWithKeyValue(
                  "Customer Name",
                  pickingItem?.customerName
                )}

                {customerAddress?.address2 !== "" &&
                  BoxItemWithKeyValue(
                    "Customer Address",
                    `${customerAddress?.address1}\n${customerAddress?.address2}`
                  )}

                {BoxItemWithKeyValue(
                  "",
                  `${customerAddress?.city},${customerAddress?.county}`
                )}
                {BoxItemWithKeyValue(
                  "",
                  `${customerAddress?.country.toUpperCase()}\n${customerAddress?.postCode.toUpperCase()}\n`
                )}
              </div>
              <div className="col-5 mt-1">
                {BoxItemWithKeyValue(
                  "Date Printed",
                  moment(pickingItem?.createdDate)?.format("DD/MM/YYYY")
                )}
                {BoxItemWithKeyValue(
                  "Order Status",
                  pickingItem?.status.replaceAll("_", " ")
                )}
                {BoxItemWithKeyValue("SO#", pickingItem?.soNum)}
                {BoxItemWithKeyValue(
                  "Order Ref. #",
                  pickingItem?.customerRefNum
                )}
                {BoxItemWithKeyValue("Comments", pickingItem?.description)}
              </div>
            </div>
          </div>
          <div
            className="col-12 table-responsive"
            style={{ backgroundColor: "white", padding: 20, paddingTop: 0 }}
          >
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ fontSize: 12, margin: 0 }}>#</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Code</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Product Name</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Batch #</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Exp. Date</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Location</th>
                  <th style={{ fontSize: 12, margin: 0 }}>Quantity</th>
                  {/* <th style={{ fontSize: 12 }}>Quantity break up</th> */}
                </tr>
              </thead>
              <tbody>
                {pickingItem?.orderPickingDetails?.map(
                  (_orderItem, _indexno) => {
                    return (
                      <tr className="col-12 p-0">
                        <td>
                          <p style={{ fontSize: 14, margin: 0 }}>
                            {_indexno + 1}
                          </p>
                        </td>
                        <td className="col-3">
                          <p style={{ fontSize: 14, margin: 0 }}>
                            {getProductCode(_orderItem?.prodId, productsList)}
                          </p>
                        </td>
                        <td className="col-5">
                          <p style={{ fontSize: 12, margin: 0 }}>
                            {getProductName(_orderItem?.prodId, productsList)}
                          </p>
                        </td>
                        <td className="col-1">
                          <p style={{ fontSize: 12, margin: 0 }}>
                            {_orderItem.batchNo}
                          </p>
                        </td>
                        <td className="col-1">
                          <p style={{ fontSize: 12, margin: 0 }}>
                            {moment(_orderItem.batchExpiry).format(
                              "DD/MM/yyyy"
                            )}
                          </p>
                        </td>
                        <td className="col-1">
                          <p style={{ fontSize: 12, margin: 0 }}>
                            {_orderItem?.shelfId}
                          </p>
                        </td>
                        <td className="col-1">
                          <p style={{ fontSize: 12, margin: 0 }}>
                            {_orderItem?.pickingQty}
                          </p>
                        </td>
                        {/* <td className="col-2">
                          <p style={{ fontSize: 12, margin: 0 }}></p>
                        </td> */}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
            <p className="mt-20">
              <u>*Please note some part boxes may be combined</u>
            </p>

            <div className="d-flex mt-10 justify-content-between">
              <p>
                Picked by <strong>{pickingItem?.pickedBy ?? "-"}</strong>{" "}
                {!!pickingItem?.pickedAt ? (
                  <>
                    <span>at </span>
                    <strong>
                      {moment(pickingItem?.pickedAt)?.format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </strong>
                  </>
                ) : (
                  ""
                )}
              </p>

              <p>
                Packed by <strong>{pickingItem?.packedBy ?? "-"}</strong>{" "}
                {!!pickingItem?.packedAt ? (
                  <>
                    <span>at </span>
                    <strong>
                      {moment(pickingItem?.packedAt)?.format(
                        "DD-MM-YYYY hh:mm A"
                      )}
                    </strong>
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>
            <p style={{ color: "grey", position: "absolute", bottom: 0 }}>
              *This is an electronically generated receipt, hence does not
              require a signature
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PickingPrintFormat;
