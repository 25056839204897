import { useContext, useEffect, useState } from "react";
import { LabelConstants } from "../../common/constants/labelConstants";
import {
  ICustomer,
  ICustomerAddress,
  IDeleteConfirmationProps,
  IProducts,
} from "../../common/models";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import moment from "moment";
import {
  getDateIfValid,
  getNumberForString,
  getProductCatNameById,
  getProductNameById,
} from "../../common/methods/globalmethods";
import { DatePicker } from "antd";
import { SalesOrderContext } from "../../controllers/contexts/salesorder.context";
import { ISODetails } from "../../common/models/salesorder";
import {
  NewDeleteConfirmationDialog,
  SalesOrderCreationStatuses,
  SalesOrderUpdateStatuses,
} from "../../common/constants/generalConstants";
import { toast } from "react-toastify";
import DeleteConfirmationComponent from "../common/deleteConfirmation.component";
import { cloneDeep } from "lodash";
import { Modal } from "bootstrap";
import dayjs from "dayjs";

var myModal;
const SalesDetailsComponent = () => {
  const {
    SOForm,
    clearSalerOrder,
    customersList,
    saveSalesOrder,
    productsList,
    productCateList,
    checkStockOnHand,
    getStockStatusColor,
  } = useContext(SalesOrderContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [soProductDetails, setSoDetails] = useState(SOForm);
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [dialoginfo, setDialoginfo] = useState<IDeleteConfirmationProps>();
  const [filteredProducts, setFilteredProducts] =
    useState<IProducts[]>(productsList);
  const isEditableSo = location.state.isEditable;

  useEffect(() => {
    myModal = new Modal(document.getElementById("deleteConfirmation"), {});
    return () => {
      clearSalerOrder();
    };
  }, []);

  useEffect(() => {
    if (soProductDetails) {
      const updatedProductsList = { ...soProductDetails }; // Create a copy
      let newList = updatedProductsList?.soProductDetails?.filter((item) => {
        return item.quantity !== 0;
      });
      updatedProductsList.soProductDetails = newList;
      setSoDetails(updatedProductsList);
    }
  }, []);

  useEffect(() => {
    if (SOForm === null) {
      navigate(RoutingConstants.sales_order);
    }
  }, [SOForm]);

  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };
  //// Function to handle checkbox clicks
  const handleCheckboxClick = (product: IProducts) => {
    const updatedProductsList = { ...soProductDetails }; // Create a copy
    const productIndex = updatedProductsList.soProductDetails.findIndex(
      (p) => getNumberForString(p.prodId) === product.productId
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList.soProductDetails.splice(productIndex, 1); // Remove from the list
    } else {
      // Item not in the list
      updatedProductsList.soProductDetails.push({
        prodId: product.productId + "",
        prodCatId: product.prodCategory,
        quantity: 0,
        unitPrice: 0,
        vat: 0,
        productAmount: 0,
        totalAmount: 0,
        taxAmount: 0,
        PIPCode: "",
      }); // Add to the list with isChecked flag
    }
    setSoDetails(updatedProductsList);
  };

  const handleDeleteSo = (id: string) => {
    const updatedProductsList = { ...soProductDetails }; // Create a copy
    const productIndex = updatedProductsList.soProductDetails.findIndex(
      (p) => p.prodId === id
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList.soProductDetails.splice(productIndex, 1); // Remove from the list
    }
    setSoDetails(updatedProductsList);
  };

  const handleQuantityChange = async (e: any, product: any) => {
    let _salesOrder = { ...soProductDetails };

    _salesOrder.soProductDetails?.map(async (_prod: ISODetails, i) => {
      if (_prod.prodId === product.prodId) {
        _prod.quantity = isNaN(parseInt(e.target.value))
          ? 0
          : parseInt(e.target.value);
        _prod.productAmount = _prod.unitPrice * _prod.quantity;
        if (!isNaN(_prod?.vat)) {
          _prod.taxAmount = (_prod.productAmount / 100) * _prod.vat;
          _prod.taxAmount = parseFloat(_prod.taxAmount?.toFixed(2));
          _prod.totalAmount = _prod.productAmount + _prod.taxAmount;
          _prod.totalAmount = parseFloat(_prod.totalAmount?.toFixed(2));
        }
        await checkStockOnHand(_prod.prodId, _prod.quantity).then((status) => {
          _prod.status = status;
          setSoDetails(_salesOrder);
        });
      }
    });
  };

  const handlePriceChange = (e: any, product: any) => {
    let _salesOrder = { ...soProductDetails };
    _salesOrder.soProductDetails.map((_prod, i) => {
      if (_prod.prodId === product.prodId) {
        _prod.unitPrice = e.target.value;
        _prod.productAmount = _prod.quantity * e.target.value;
        if (!isNaN(_prod.vat)) {
          _prod.taxAmount = (_prod.productAmount / 100) * _prod.vat;
          _prod.taxAmount = parseFloat(_prod.taxAmount?.toFixed(2));
          _prod.totalAmount = _prod.productAmount + _prod.taxAmount;
          _prod.totalAmount = parseFloat(_prod.totalAmount?.toFixed(2));
        }
      }
    });
    setSoDetails(_salesOrder);
  };
  const handleVatPrChange = (e: any, product: any) => {
    let value = e.target.value;
    value = isNaN(value) ? 0 : value;
    let _salesOrder = { ...soProductDetails };
    _salesOrder.soProductDetails.map((_prod, i) => {
      if (_prod.prodId === product.prodId) {
        _prod.productAmount = _prod?.unitPrice * _prod?.quantity;
        _prod.vat = value;
        _prod.taxAmount = (_prod.productAmount / 100) * _prod.vat;
        _prod.taxAmount = parseFloat(_prod.taxAmount?.toFixed(2));
        _prod.totalAmount = _prod.productAmount + _prod.taxAmount;
        _prod.totalAmount = parseFloat(_prod.totalAmount?.toFixed(2));
      }
    });
    setSoDetails(_salesOrder);
  };
  const calculateTotalTaxVal = () => {
    let _salesOrder = { ...soProductDetails };
    let totalTax = 0;
    _salesOrder.soProductDetails.map((_prod, i) => {
      totalTax = totalTax + _prod.taxAmount;
      totalTax = parseFloat(totalTax?.toFixed(2));
    });
    let result = parseFloat(totalTax?.toFixed(2));
    return isNaN(result) ? 0 : result;
  };
  const calculateTotalAmountVal = (): any => {
    let _salesOrder = { ...soProductDetails };
    let totalAmt = soProductDetails?.carrierChargeAmount * 1;
    _salesOrder.soProductDetails.map((_prod, i) => {
      totalAmt = totalAmt + _prod.totalAmount;
    });
    return (Math.round(totalAmt * 100) / 100).toFixed(2);
  };

  const handleFormChange = (types: string[], values: any[]) => {
    let _salesOrder = { ...soProductDetails };
    types?.map((item, index) => {
      _salesOrder[item] = values[index];
    });
    setSoDetails(_salesOrder);
  };

  const handleUpdateSalesOrder = () => {
    console.log(soProductDetails);
    let _updatedSo = { ...soProductDetails };
    if (_updatedSo?.soProductDetails?.length > 0) {
      _updatedSo.totalTaxAmount = calculateTotalTaxVal();
      _updatedSo.totalSaleOrderAmount = calculateTotalAmountVal();
      // delete _updatedSo["createdDate"];
      // delete _updatedSo["createdBy"];
      // delete _updatedSo["modifiedDate"];
      // delete _updatedSo["modifiedBy"];
      saveSalesOrder(_updatedSo);
      navigate(RoutingConstants.sales_order);
    } else {
      toast.error("Please add products to purchase order");
    }
  };

  const showDialog = (
    onCancel: Function,
    onConfirm: Function,
    header: string,
    title_prefix: string,
    title: string = null
  ) => {
    let dialog: IDeleteConfirmationProps = cloneDeep(
      NewDeleteConfirmationDialog
    );
    dialog.cancel = onCancel;
    dialog.confirm = onConfirm;
    dialog.title = title;
    dialog.header = header;
    dialog.title_prefix = title_prefix;
    setDialoginfo(dialog);
    myModal.show();
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            {/* <h4 className="page-title">{`${LabelConstants.Customer} ${LabelConstants.Details}`}</h4> */}
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {LabelConstants.OrderOut}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.sales_order}
                      title={LabelConstants.SalesOrder}
                    >
                      {LabelConstants.SalesOrder}
                    </NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.SalesOrder} ${LabelConstants.Details}`}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      {soProductDetails ? (
        <>
          <section className="content">
            <div className="row">
              <div className="col-12 col-lg-8">
                <div className="box">
                  <div className="box-header  bg-primary d-flex justify-content-between">
                    <h4 className="box-title">Products </h4>
                    {isEditableSo && soProductDetails.status === "DRAFT" && (
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#modal-right"
                      >
                        Add Product
                      </button>
                    )}
                  </div>
                  <div className="box-body">
                    {/* products Table */}
                    <div className="table-responsive">
                      <table className="table product-overview table-bordered text-center">
                        <thead className="table-primary">
                          <tr>
                            <th>Product Category</th>
                            <th>Product Name</th>
                            <th>Quantity</th>
                            <th>Price/ unit</th>
                            <th className="text-align:center">VAT(%)</th>
                            <th className="text-align:center">Tax</th>
                            <th className="text-align:center">Amount</th>
                            <th className="text-align:center">Actions</th>
                          </tr>
                        </thead>
                        {
                          <tbody>
                            {soProductDetails?.soProductDetails?.length > 0 ? (
                              soProductDetails?.soProductDetails?.map(
                                (product: ISODetails, i: number) => {
                                  return (
                                    <tr key={i}>
                                      <td className="text-start">
                                        {getProductCatNameById(
                                          product.prodCatId,
                                          productCateList
                                        )}
                                      </td>
                                      <td className="text-start">
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                          }}
                                        >
                                          <div style={{ width: "96%" }}>
                                            {getProductNameById(
                                              product.prodId,
                                              productsList
                                            )}
                                          </div>
                                          <i
                                            style={{
                                              backgroundColor:
                                                getStockStatusColor(
                                                  product?.status
                                                ),
                                              height: 15,
                                              width: 15,
                                              borderRadius: 15,
                                              alignSelf: "center",
                                              marginLeft: 5,
                                            }}
                                          />
                                        </div>
                                      </td>
                                      <td className="w-150">
                                        <input
                                          type="number"
                                          className="form-control text-start"
                                          readOnly={
                                            isEditableSo &&
                                            soProductDetails.status === "DRAFT"
                                              ? false
                                              : true
                                          }
                                          min={0}
                                          onChange={(e) => {
                                            handleQuantityChange(e, product);
                                          }}
                                          value={product.quantity}
                                        />
                                      </td>
                                      <td className="w-150">
                                        <input
                                          type="number"
                                          className="form-control text-start"
                                          readOnly={
                                            isEditableSo &&
                                            soProductDetails.status === "DRAFT"
                                              ? false
                                              : true
                                          }
                                          min={0}
                                          onChange={(e) => {
                                            handlePriceChange(e, product);
                                          }}
                                          value={product?.unitPrice}
                                        />
                                      </td>
                                      <td className="w-90">
                                        <input
                                          type="text"
                                          className="form-control"
                                          readOnly={
                                            isEditableSo &&
                                            soProductDetails.status === "DRAFT"
                                              ? false
                                              : true
                                          }
                                          min={0}
                                          value={product?.vat}
                                          onChange={(e) =>
                                            handleVatPrChange(e, product)
                                          }
                                        />
                                      </td>
                                      <td>
                                        {product?.taxAmount
                                          ? product?.taxAmount
                                          : 0}
                                      </td>
                                      <td>{product.totalAmount}</td>
                                      <td>
                                        <span
                                          onClick={() => {
                                            let canEdit =
                                              isEditableSo &&
                                              soProductDetails.status ===
                                                "DRAFT";
                                            if (canEdit) {
                                              showDialog(
                                                () => {},
                                                () => {
                                                  handleDeleteSo(
                                                    product.prodId
                                                  );
                                                },
                                                undefined,
                                                undefined,
                                                getProductNameById(
                                                  product.prodId,
                                                  productsList
                                                )
                                              );
                                            }
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash text-danger fs-3"
                                            aria-hidden="true"
                                          ></i>
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            ) : (
                              <tr>
                                <td colSpan={9} align="center">
                                  <br />
                                  <p>No products added to sales order</p>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        }
                      </table>

                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          clearSalerOrder();
                          setSoDetails(null);
                          navigate(RoutingConstants.sales_order);
                        }}
                      >
                        Close
                      </button>
                      {isEditableSo && (
                        <button
                          type="button"
                          className="btn btn-primary float-end"
                          onClick={() => handleUpdateSalesOrder()}
                        >
                          Save changes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* sales order container*/}
              <div className="col-12 col-lg-4">
                <div className="box">
                  <div className="box-header bg-info p-3">
                    <h4 className="box-title">Sales Order Details</h4>
                    <p className="p-0 m-0">
                      #{LabelConstants.SoNumber} : {soProductDetails?.soNum}
                    </p>
                  </div>
                  <div className="box-body">
                    <div className="row">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.CustomerName}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          className="form-select"
                          disabled
                          value={soProductDetails?.customerId}
                          onChange={(e) => {
                            handleFormChange(["customerId"], [e.target.value]);
                          }}
                        >
                          {customersList?.map(
                            (customer: ICustomer, i: number) => {
                              return (
                                <option
                                  key={customer?.custId}
                                  value={customer?.custId}
                                >
                                  {customer?.custName}
                                </option>
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.CreatedDate}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          className="form-control"
                          readOnly
                          value={moment(soProductDetails?.soCreatedDate).format(
                            "DD/MM/YYYY"
                          )}
                        />
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.ExpectedDeliveryDate}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <DatePicker
                          className="form-control bg-white"
                          value={getDateIfValid(soProductDetails?.deliveryDate)}
                          disabled={!isEditableSo}
                          format="DD-MM-YYYY"
                          onChange={(e, ds) => {
                            let date = e?.format("YYYY-MM-DD");
                            handleFormChange(["deliveryDate"], [date]);
                          }}
                        />
                      </div>
                    </div>

                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="fw-bold">
                          {LabelConstants.DeliveryAddress}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          className="form-select"
                          disabled={!isEditableSo}
                          value={soProductDetails?.addressId}
                          onChange={(e) => {
                            let customer: ICustomer = customersList?.find(
                              (item) =>
                                item?.custId ===
                                parseInt(soProductDetails?.customerId)
                            );
                            let ca = customer?.custAddresses?.find(
                              (item) =>
                                item?.custAddressId === parseInt(e.target.value)
                            );
                            handleFormChange(
                              ["addressId", "deliveryAddress"],
                              [
                                e.target.value,
                                `${ca?.address1},${ca?.address2},${ca?.city},${ca?.country},${ca?.postCode}`,
                              ]
                            );
                          }}
                        >
                          <option>--</option>
                          {customersList?.map(
                            (customer: ICustomer, customerIndex: number) => {
                              if (
                                customer?.custId !==
                                parseInt(soProductDetails?.customerId)
                              ) {
                                return;
                              }
                              return customer?.custAddresses?.map(
                                (
                                  address: ICustomerAddress,
                                  addressIndex: number
                                ) => {
                                  return (
                                    <option
                                      key={address?.custAddressId}
                                      value={address?.custAddressId}
                                    >
                                      {`${address?.address1},
                                  ${address?.address2},
                                  ${address?.city},
                                  ${address?.country},
                                  ${address?.postCode}`}
                                    </option>
                                  );
                                }
                              );
                            }
                          )}
                        </select>
                      </div>
                    </div>

                    <div className="row mt-20">
                      <div className="col-4">
                        <label
                          className="form-label fw-bold"
                          htmlFor="goodsReceiptNumber"
                        >
                          {LabelConstants.Status}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <select
                          className="form-select"
                          disabled={!isEditableSo}
                          value={soProductDetails.status}
                          onChange={(e) => {
                            handleFormChange(["status"], [e.target.value]);
                          }}
                        >
                          {(isEditableSo
                            ? SalesOrderCreationStatuses
                            : SalesOrderUpdateStatuses
                          )?.map((_status: string, _statusIdx: number) => {
                            return (
                              <option
                                key={`statusOption${_statusIdx}`}
                                value={_status}
                              >
                                {_status}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label
                          className="form-label fw-bold"
                          htmlFor="goodsReceiptNumber"
                        >
                          {LabelConstants.referenceNumber}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          className="form-control"
                          readOnly={!isEditableSo}
                          value={soProductDetails.customerRefNum}
                          onChange={(e) =>
                            handleFormChange(
                              ["customerRefNum"],
                              [e.target.value]
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="form-label fw-bold">
                          {LabelConstants.Description}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <textarea
                          value={soProductDetails?.reason}
                          disabled={!isEditableSo}
                          onChange={(e) =>
                            handleFormChange(["reason"], [e.target.value])
                          }
                          rows={5}
                          className="form-control"
                          placeholder={LabelConstants.Description}
                        />
                      </div>
                    </div>

                    <div className="row mt-20">
                      <div className="col-4">
                        <label className="fw-bold">
                          {LabelConstants.Carriage}
                          <span className="text-danger">*</span>
                        </label>
                      </div>
                      <div className="col-8">
                        <input
                          className="form-control"
                          disabled={!isEditableSo}
                          type="number"
                          value={soProductDetails?.carrierChargeAmount}
                          onChange={(e) =>
                            handleFormChange(
                              ["carrierChargeAmount"],
                              [e.target.value]
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className=" d-flex mt-20">
                      <div className="col-4">
                        <h5 className="fw-bold">Tax</h5>
                      </div>
                      <div className="col-8">
                        <p className="text-end fw-700 h6">
                          {LabelConstants.DollarSign}{calculateTotalTaxVal()}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mt-20">
                      <div className="col-4">
                        <h5 className="bt-1 fw-bold">Receivable Amount</h5>
                      </div>
                      <div className="col-8">
                        <p className="bt-1 text-end fw-900 fs-18">
                          {LabelConstants.DollarSign}{calculateTotalAmountVal()}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Products list modal */}
          <div
            className="modal modal-right fade overflow-auto"
            id="modal-right"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Products List</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body" style={{ overflowY: "auto" }}>
                  <input
                    type="search"
                    placeholder="Search Product"
                    value={searchProduct}
                    className="form-control"
                    onChange={onClickSearch}
                  />
                  <br />
                  <ul className="list-group">
                    {filteredProducts?.length > 0 &&
                      filteredProducts.map((product: IProducts) => (
                        <li
                          className={`list-group-item ${
                            soProductDetails?.soProductDetails?.some(
                              (p) =>
                                getNumberForString(p.prodId) ===
                                product.productId
                            ) && "d-none"
                          }`}
                          key={product.productId}
                        >
                          <input
                            type="checkbox"
                            id={product.productId.toString()}
                            checked={soProductDetails.soProductDetails.some(
                              (p) =>
                                getNumberForString(p.prodId) ===
                                product.productId
                            )} // Set checked based on presence in selectedProducts
                            onChange={() => handleCheckboxClick(product)} // Set checked based on presence in selectedProducts
                          />
                          <label htmlFor={product.productId.toString()}>
                            <b>{product.productCode}</b> - {product.productName}
                          </label>
                        </li>
                      ))}
                  </ul>
                </div>
                <div className="modal-footer modal-footer-uniform">
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    data-bs-dismiss="modal"
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <DeleteConfirmationComponent
        title={dialoginfo?.title}
        cancel={dialoginfo?.cancel}
        confirm={dialoginfo?.confirm}
        header={dialoginfo?.header}
        title_prefix={dialoginfo?.title_prefix}
      />
    </>
  );
};

export default SalesDetailsComponent;
