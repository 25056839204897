import React, { useEffect, useState } from "react";
import useAuth from "../../controllers/hooks/useAuth";

const LoginComponent = (props) => {
  const { onContinueSession = null, userName = null, password = null } = props;
  const {
    loginRequest,
    setLoginRequest,
    authenticateUser,
    validatePasscode,
    forgotPwdRequest,
    setForgotPwdRequest,
    passwordReset,
    forgotPasswordStep1,
  } = useAuth();
  const [showPasscodeSection, setShowPasscodeSection] = useState(false);
  const [showForgotPwdSection, setShowForgotPwdSection] = useState(false);
  const [newPasswordSectionInFP, setNewPasswordSectionInFP] = useState(false);
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
    passcode: "",
  });
  const [FpformErrors, setFpFormErrors] = useState({
    username: "",
    passcode: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (userName !== null) {
      handleUsernameChange(userName);
    }
    if (password !== null) {
      handlePasswordChange(password);
    }
  }, [userName, password]);

  const handleForgotPassword = () => {
    setNewPasswordSectionInFP(false);
    setShowForgotPwdSection(true);
  };

  const formValidation = () => {
    const { username, password } = loginRequest;
    const usernameError = username ? "" : "Username is required";
    const passwordError = password ? "" : "Password is required";
    setFormErrors({
      username: usernameError,
      password: passwordError,
      passcode: "",
    });
    return !usernameError && !passwordError;
  };

  const handleUsernameChange = (value) => {
    setLoginRequest({ ...loginRequest, username: value });
    setFormErrors({ ...formErrors, username: "" });
  };

  const handlePasswordChange = (value) => {
    setLoginRequest({ ...loginRequest, password: value });
    setFormErrors({ ...formErrors, password: "" });
  };

  const handlePasscodeChange = (e) => {
    setLoginRequest({ ...loginRequest, passcode: e.target.value });
    setFormErrors({ ...formErrors, passcode: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = formValidation();
    if (isValid) {
      props?.upateSpinner(true, "Please Wait...");
      if (await authenticateUser(onContinueSession === null)) {
        props?.upateSpinner(false);
        if (onContinueSession) {
          onContinueSession();
        } else {
          setShowPasscodeSection(true);
        }
      } else {
        props?.upateSpinner(false);
      }
    }
  };

  const handlePasscodeSubmit = async (e) => {
    e.preventDefault();
    if (loginRequest?.passcode !== "") {
      props?.upateSpinner(true, "Please Wait...");
      await validatePasscode();
      props?.upateSpinner(false);
      if (onContinueSession) {
        onContinueSession();
      }
    } else {
      setFormErrors({ ...formErrors, passcode: "Incorrect passcode" });
    }
  };

  const handleFpUsernameChange = (e) => {
    setForgotPwdRequest({ ...forgotPwdRequest, username: e.target.value });
    setFpFormErrors({ ...FpformErrors, username: "" });
  };
  const handleFpPasswordChange = (e) => {
    setForgotPwdRequest({ ...forgotPwdRequest, newPassword: e.target.value });
    setFpFormErrors({ ...FpformErrors, newPassword: "" });
  };
  const handleFpConfirmPasswordChange = (e) => {
    setForgotPwdRequest({
      ...forgotPwdRequest,
      confirmPassword: e.target.value,
    });
    setFpFormErrors({ ...FpformErrors, confirmPassword: "" });
  };
  const handleFpPasscodeChange = (e) => {
    setForgotPwdRequest({ ...forgotPwdRequest, passcode: e.target.value });
    setFpFormErrors({ ...FpformErrors, passcode: "" });
  };
  const fpFormValidation = () => {
    const { username, newPassword, passcode, confirmPassword } =
      forgotPwdRequest;
    const usernameError = username ? "" : "Username is required";
    const passwordError = newPassword ? "" : "New Password is required";
    const confirmPasswordError = confirmPassword
      ? newPassword === confirmPassword
        ? ""
        : "Password should match with New password"
      : "Confirm Password is required";
    const passcodeError = passcode ? "" : "Passcode is required";
    setFpFormErrors({
      username: usernameError,
      newPassword: passwordError,
      confirmPassword: confirmPasswordError,
      passcode: passcodeError,
    });
    return (
      !usernameError &&
      !passwordError &&
      !passcodeError &&
      !confirmPasswordError
    );
  };

  const fpNewPassFormValidation = () => {
    const { username, newPassword, passcode, confirmPassword } =
      forgotPwdRequest;
    const usernameError = username ? "" : "Username is required";
    const passcodeError = passcode ? "" : "Passcode is required";
    setFpFormErrors({
      username: usernameError,
      newPassword: "",
      confirmPassword: "",
      passcode: passcodeError,
    });
    return !usernameError && !passcodeError;
  };

  const handleFpSubmit = async (e) => {
    e.preventDefault();
    const isValid = fpFormValidation();
    if (isValid) {
      props?.upateSpinner(true, "Please Wait...");
      if (await passwordReset()) {
        props?.upateSpinner(false);
        setShowForgotPwdSection(false);
        setNewPasswordSectionInFP(false);
      } else {
        props?.upateSpinner(false);
      }
    }
  };

  const handleNewPasswordSectionInFP = async (e) => {
    e.preventDefault();
    const isValid = fpNewPassFormValidation();
    if (isValid) {
      props?.upateSpinner(true, "Please Wait...");
      if (await forgotPasswordStep1()) {
        props?.upateSpinner(false);
        setNewPasswordSectionInFP(true);
      } else {
        props?.upateSpinner(false);
      }
    }
  };

  const passcodeDetails = () => (
    <div className="bg-white rounded10 shadow-lg">
      <div className="content-top-agile p-20 pb-0">
        <h2 className="text-primary"> Enter Passcode</h2>
        <p className="mb-0">Enter your passcode to continue.</p>
      </div>
      <div className="p-40">
        <div className="form-group">
          <div className="input-group mb-3">
            <span className="input-group-text bg-transparent">
              <i className="ti-key"></i>
            </span>
            <input
              type="password"
              className="form-control ps-15 bg-transparent"
              placeholder="Passcode"
              value={loginRequest.passcode}
              onChange={handlePasscodeChange}
            />
          </div>
          {formErrors.passcode && (
            <p className="text-danger">{formErrors.passcode}</p>
          )}
        </div>
        <div className="row">
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-danger mt-10"
              onClick={handlePasscodeSubmit}
            >
              SUBMIT
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  const forgotPSWDDetails = () => (
    <div className="bg-white rounded10 shadow-lg">
      <div className="content-top-agile p-20 pb-0">
        <h2 className="text-primary"> Forgot Password</h2>
        <p className="mb-0">Enter below details to continue.</p>
      </div>
      <div className="p-40">
        {!newPasswordSectionInFP ? (
          <>
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-text bg-transparent">
                  <i className="ti-user"></i>
                </span>
                <input
                  type="text"
                  className="form-control ps-15 bg-transparent"
                  placeholder="Username"
                  value={forgotPwdRequest.username}
                  onChange={handleFpUsernameChange}
                />
              </div>
              {FpformErrors.username && (
                <p className="text-danger">{FpformErrors.username}</p>
              )}
            </div>
            <div className="form-group">
              <div className="input-group mt-3">
                <span className="input-group-text bg-transparent">
                  <i className="ti-key"></i>
                </span>
                <input
                  type="password"
                  className="form-control ps-15 bg-transparent"
                  placeholder="Passcode"
                  value={
                    forgotPwdRequest.passcode ? forgotPwdRequest.passcode : ""
                  }
                  onChange={handleFpPasscodeChange}
                />
              </div>
              {FpformErrors.passcode && (
                <p className="text-danger">{FpformErrors.passcode}</p>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="form-group">
              <div className="input-group mt-3">
                <span className="input-group-text bg-transparent">
                  <i className="ti-lock"></i>
                </span>
                <input
                  type="password"
                  className="form-control ps-15 bg-transparent"
                  placeholder="New password"
                  value={forgotPwdRequest.newPassword}
                  onChange={handleFpPasswordChange}
                />
              </div>
              {FpformErrors.newPassword && (
                <p className="text-danger">{FpformErrors.newPassword}</p>
              )}
            </div>
            <div className="form-group">
              <div className="input-group mt-3">
                <span className="input-group-text bg-transparent">
                  <i className="ti-lock"></i>
                </span>
                <input
                  type="password"
                  className="form-control ps-15 bg-transparent"
                  placeholder="Confirm password"
                  value={forgotPwdRequest.confirmPassword}
                  onChange={handleFpConfirmPasswordChange}
                />
              </div>
              {FpformErrors.confirmPassword && (
                <p className="text-danger">{FpformErrors.confirmPassword}</p>
              )}
            </div>
          </>
        )}
        <div className="row">
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-info mt-10 mx-10"
              onClick={() => {
                setForgotPwdRequest({
                  ...forgotPwdRequest,
                  username: "",
                  passcode: null,
                });
                setShowForgotPwdSection(false);
              }}
            >
              Back
            </button>
            {newPasswordSectionInFP ? (
              <button
                type="submit"
                className="btn btn-danger mt-10 mx-10"
                onClick={handleFpSubmit}
              >
                SUBMIT
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-danger mt-10 mx-10"
                onClick={handleNewPasswordSectionInFP}
              >
                SUBMIT
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const loginDetails = () => (
    <div className="bg-white rounded10 shadow-lg">
      <div className="content-top-agile p-20 pb-0">
        {onContinueSession === null && (
          <h2 className="text-primary">Let's Get Started</h2>
        )}
        <p className="mb-0">Sign in to continue to Consult Veda.</p>
      </div>
      <div className="p-40">
        <div className="form-group">
          <div className="input-group">
            <span className="input-group-text bg-transparent">
              <i className="ti-user"></i>
            </span>
            <input
              type="text"
              className="form-control ps-15 bg-transparent"
              placeholder="Username"
              value={loginRequest.username}
              onChange={(e) => handleUsernameChange(e.target.value)}
            />
          </div>
          {formErrors.username && (
            <span className="text-danger">{formErrors.username}</span>
          )}
        </div>
        <div className="form-group">
          <div className="input-group mt-3">
            <span className="input-group-text bg-transparent">
              <i className="ti-lock"></i>
            </span>
            <input
              type="password"
              className="form-control ps-15 bg-transparent"
              placeholder="Password"
              value={loginRequest.password}
              onChange={(e) => handlePasswordChange(e.target.value)}
            />
          </div>
          {formErrors.password && (
            <span className="text-danger">{formErrors.password}</span>
          )}
        </div>
        <div className="row">
          {onContinueSession === null && (
            <div className="col-6">
              <div className="checkbox">
                <input type="checkbox" id="basic_checkbox_1" />
                <label htmlFor="basic_checkbox_1">Remember Me</label>
              </div>
            </div>
          )}
          {onContinueSession === null && (
            <div className="col-6">
              <div
                className="fog-pwd text-end"
                onClick={() => {
                  handleForgotPassword();
                }}
              >
                <a href="javascript:void(0)" className="hover-warning">
                  <i className="ion ion-locked"></i> Forgot Password?
                </a>
                <br />
              </div>
            </div>
          )}
          <div className="col-12 text-center">
            <button
              type="submit"
              className="btn btn-danger mt-10"
              onClick={handleSubmit}
            >
              SIGN IN
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  const displayView = (viewDisplay) => {
    if (onContinueSession === null) {
      return (
        <div className="hold-transition theme-primary bg-img vh-100">
          <div className="container h-p100">
            <div className="row align-items-center justify-content-md-center h-p100">
              <div className="col-12">
                <div className="row justify-content-center g-0">
                  <div className="col-lg-5 col-md-5 col-12">
                    {viewDisplay()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return viewDisplay();
    }
  };

  return (
    <>
      {showPasscodeSection
        ? displayView(passcodeDetails)
        : showForgotPwdSection
        ? displayView(forgotPSWDDetails)
        : displayView(loginDetails)}
    </>
  );
};

export default LoginComponent;
