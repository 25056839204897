import FeatherIcon from "feather-icons-react";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import { NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../controllers/contexts/app.context";
import { useContext } from "react";
import { Dropdown } from "bootstrap";
import Logo from "../../assets/images/consult_veda_logo.png";

const HeaderComponent = () => {
  const navigate = useNavigate();
  const { signOut, userContext } = useContext(AppContext);

  return (
    <header className="main-header">
      <div className="d-flex align-items-center logo-box justify-content-center bg-white">
        <NavLink
          className={"logo"}
          to={RoutingConstants.dashboard}
          title={LabelConstants.Dashboard}
        >
          <div className="logo h-p100">
            <img id="logo-image" src={Logo} alt="logo" height={65}/>
          </div>
        </NavLink>
      </div>
      <nav className="navbar navbar-static-top">
        <div className="app-menu">
          <ul className="header-megamenu nav">
            <li className="btn-group nav-item">
              <a
                id="header-sidemenu"
                href="javascript:void(0)"
                className="waves-effect waves-light nav-link push-btn btn-primary-light"
                data-toggle="push-menu"
                role="button"
              >
                <FeatherIcon icon="align-left" />
              </a>
            </li>
            <li className="btn-group d-lg-inline-flex d-none">
              <div className="app-menu">
                <div className="search-bx mx-5">
                  <form>
                    <div className="input-group">
                      <input
                        type="search"
                        className="form-control"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="button-addon3"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn"
                          type="submit"
                          id="button-addon3"
                        >
                          <FeatherIcon icon="search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div className="navbar-custom-menu r-side">
          <ul className="nav navbar-nav">
            <li className="btn-group nav-item d-lg-inline-flex d-none">
              <a
                href="#"
                data-provide="fullscreen"
                className="waves-effect waves-light nav-link full-screen btn-warning-light"
                title="Full Screen"
              >
                <FeatherIcon icon="maximize" />
              </a>
            </li>
            <li className="dropdown notifications-menu">
              <a
                href="#"
                className="waves-effect waves-light dropdown-toggle btn-info-light"
                data-bs-toggle="dropdown"
                title="Notifications"
              >
                <FeatherIcon icon="bell" />
              </a>
              <ul className="dropdown-menu animated bounceIn">
                <li className="header">
                  <div className="p-20">
                    <div className="flexbox">
                      <div>
                        <h4 className="mb-0 mt-0">Notifications</h4>
                      </div>
                      <div>
                        <a href="#" className="text-danger">
                          Clear All
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  <ul className="menu sm-scrol">
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-info"></i> Curabitur id
                        eros quis nunc suscipit blandit.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-warning text-warning"></i> Duis
                        malesuada justo eu sapien elementum, in semper diam
                        posuere.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-users text-danger"></i> Donec at
                        nisi sit amet tortor commodo porttitor pretium a erat.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-shopping-cart text-success"></i> In
                        gravida mauris et nisi
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-danger"></i> Praesent eu
                        lacus in libero dictum fermentum.
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-primary"></i> Nunc
                        fringilla lorem
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-user text-success"></i> Nullam
                        euismod dolor ut quam interdum, at scelerisque ipsum
                        imperdiet.
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a href="#">View all</a>
                </li>
              </ul>
            </li>

            <li className="dropdown user user-menu">
              <a
                href="#"
                className="waves-effect waves-light w-auto l-h-12 bg-transparent py-0 no-shadow"
                data-bs-toggle="dropdown"
                title="User"
                onClick={() => {
                  let menudd = new Dropdown("#menudd");
                  menudd.toggle();
                }}
              >
                <img
                  src={require(`../../assets/images/avatar/${
                    userContext?.gender === "FEMALE"
                      ? "avatar-2.png"
                      : "avatar-1.png"
                  }`)}
                  className="avatar rounded-10 bg-primary-light h-40 w-40"
                  alt=""
                />
              </a>
              <ul className="dropdown-menu animated flipInX" id="menudd">
                <li className="user-body">
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    onClick={() => {
                      navigate(RoutingConstants.profile);
                      let menudd = new Dropdown("#menudd");
                      menudd.toggle();
                    }}
                  >
                    <i className="ti-user text-muted me-2"></i> Profile
                  </a>
                  {/* <a className="dropdown-item" href="mailbox.html">
                    <i className="ti-settings text-muted me-2"></i> Email
                  </a> */}
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    onClick={() => {
                      navigate(RoutingConstants.changePassword);
                      let menudd = new Dropdown("#menudd");
                      menudd.toggle();
                    }}
                  >
                    <i className="ti-key text-muted me-2"></i> Change Password
                  </a>
                  <div className="dropdown-divider"></div>
                  <a
                    className="dropdown-item"
                    href="javascript:void(0)"
                    onClick={() => {
                      navigate("/");
                      signOut();
                    }}
                  >
                    <i className="ti-lock text-muted me-2"></i> Logout
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default HeaderComponent;
