import { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { LabelConstants } from "../../common/constants/labelConstants";
import { RoutingConstants } from "../../common/constants/routingConstants";
import {
  ERROR,
  FORM_MODES,
  NewReturnOrders,
  PurchaseStatuses,
} from "../../common/constants/generalConstants";
import { IDocuments } from "../../common/models";
import moment from "moment";
import { DatePicker } from "antd";
import { toast } from "react-toastify";
import _ from "lodash";
import {
  getDateIfValid,
  getNumberForString,
  getProductCatNameById,
  isNullUndefined,
} from "../../common/methods/globalmethods";
import Select from "react-select";
import { ReturnOrdersContext } from "../../controllers/contexts/returnorders.context";
import Dropzone from "react-dropzone";
import { cloneDeep } from "lodash";
import {
  IROProductDetails,
  IReturnOrders,
  IReturnsSalesOrderDetails,
} from "../../common/models/returnorders";
import validator from "../../common/models/validator";

interface FormErrors {
  salesOrder?: string;
  returnDate?: string;
  address?: string;
  status?: string;
  reason?: string;
  carriage?: number;
}

const VALIDATOR = {
  salesOrder: [(value) => (_.isEmpty(value) ? `Select sales order` : null)],
  returnDate: [(value) => (_.isEmpty(value) ? `Enter return date` : null)],
  address: [(value) => (_.isEmpty(value) ? `Select address value` : null)],
  status: [
    (value) =>
      _.isEmpty(value) || value === "--" ? `Select status type` : null,
  ],
  reason: [
    (value) =>
      _.isEmpty(value) || value?.length < 10
        ? `Return reason must be atlest 10 characters`
        : null,
  ],
  carriage: [(value) => (value < 0 ? `Enter valid carriage amount` : null)],
};

const ReturnOrdersFormcomponent = () => {
  const {
    roproductsList,
    productCateList,
    createNewReturnOrders,
    getNextRONumber,
    nextRO,
    clearRONumber,
    setUploadedFiles,
    uploadedFiles,
    viewFiles,
    setViewFiles,
    getSalesOrderList,
    salesOrderList,
    customersList,
    getROProductsList,
    returnOrdersForm,
    clearViewReturnOrder,
    updateReturnOrders,
  } = useContext(ReturnOrdersContext);
  const location = useLocation();

  const navigate = useNavigate();
  const [searchProduct, setSearchProduct] = useState<any>("");
  const [filteredProducts, setFilteredProducts] =
    useState<IROProductDetails[]>(roproductsList);
  const [selectedListOption, setSelectedListOption] = useState<string>("");
  const viewFilesList = viewFiles.filter((_file) =>
    _file.deleted !== undefined ? _file.deleted === false : true
  );
  const [currentCustomerAddress, setCurrentCustomerAddress] = useState<any>([]);
  const [selectedProducts, setSelectedProducts] =
    useState<IReturnOrders>(NewReturnOrders);
  const [error, setError] = useState<FormErrors>({});
  const [modeType, setModeType] = useState(FORM_MODES.Create);
  const [activeURL, setactiveURL] = useState("");

  useEffect(() => {
    const filteredList = roproductsList.filter((product) => {
      const searchItem = searchProduct?.toLowerCase();
      return (
        product?.productName?.toLowerCase()?.includes(searchItem) ||
        product?.productCode?.toLowerCase()?.includes(searchItem)
      );
    });
    setFilteredProducts(filteredList);
  }, [searchProduct, roproductsList]);

  useEffect(() => {
    getSalesOrderList();
    let mode = FORM_MODES.Create;
    if (location?.pathname === RoutingConstants.returnordersCreate) {
      if (!nextRO) {
        getNextRONumber();
      }
    } else if (location?.pathname === RoutingConstants.returnordersEdit) {
      mode = FORM_MODES.Edit;
    } else if (location?.pathname === RoutingConstants.returnordersDetails) {
      mode = FORM_MODES.View;
    }
    setModeType(mode);

    if (mode === FORM_MODES.Edit || mode === FORM_MODES.View) {
      if (isNullUndefined(returnOrdersForm?.returnOrderDetails)) {
        navigate(RoutingConstants.returnorders);
        return;
      }
      setSelectedProducts(returnOrdersForm);
      updateAddress(returnOrdersForm, returnOrdersForm?.addressId);
    }

    return () => {
      clearRONumber();
      clearViewReturnOrder();
    };
  }, []);

  const onClickSearch = (e) => {
    setSearchProduct(e.target.value);
  };

  const handleFileUpload = (files: any[]) => {
    console.log(files);
    const _viewFilesCount: number = viewFilesList.length;
    const _filesCount: number =
      files.length + uploadedFiles.length + (_viewFilesCount ?? 0);
    if (files.length > 0) {
      if (_filesCount > 5) {
        toast.warning(LabelConstants.FileUploadCountExceeded);
      } else {
        files.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
        setUploadedFiles([...uploadedFiles, ...files]);
      }
    }
  };

  const handleRemoveFile = (idx: number) => {
    const _files = cloneDeep(uploadedFiles);
    _files.splice(idx, 1);
    setUploadedFiles(_files);
  };

  const handleRemoveOldFile = (docId: number) => {
    const _files: IDocuments[] = cloneDeep(viewFiles);
    const idx: number = _files.findIndex((_) => _.documentId === docId);
    if (idx >= 0) {
      _files[idx].deleted = true;
      setViewFiles(_files);
    }
  };

  //// Function to handle checkbox clicks
  const handleCheckboxClick = (product) => {
    const updatedProductsList = { ...selectedProducts }; // Create a copy
    const productIndex = updatedProductsList.returnOrderDetails.findIndex(
      (p) => p.productCode === product.productCode
    );
    if (productIndex !== -1) {
      // Item already in the list
      updatedProductsList.returnOrderDetails =
        updatedProductsList.returnOrderDetails.filter(
          (item) => item.productCode === product?.productCode
        ); // Remove from the list
    } else {
      // Item not in the list
      let items = filteredProducts.filter(
        (item) => item.productCode === product?.productCode
      );
      items?.map(
        (item) =>
          updatedProductsList.returnOrderDetails.push({
            ...item,
            selected: true,
          }) // Add to the list with isChecked flag
      );
    }
    setSelectedProducts(updatedProductsList);
  };

  const handleProductQtyChange = (e: any, product: any) => {
    let _newReturnOrder = { ...selectedProducts };
    _newReturnOrder.returnOrderDetails.map((_prod, i) => {
      if (
        _prod.productId === product.productId &&
        _prod?.batchNo === product?.batchNo
      ) {
        let value = e.target.value ?? 0;
        if (value > _prod.shippedQty) {
          toast.error(
            `Quantity cannot be greater than shipped quantity(${_prod?.shippedQty})`
          );
          return;
        }
        _prod.returnQty = value;
        _prod.productAmount = _prod.unitPrice * _prod.returnQty;
        if (_prod.vatPercentage) {
          _prod.vat = (_prod.productAmount / 100) * _prod.vatPercentage;
          _prod.totalAmount = _prod.productAmount + _prod.vat;
        }
      }
    });
    setSelectedProducts(_newReturnOrder);
  };

  const handleProductPriceChange = (e: any, product: any) => {
    let _newReturnOrder = { ...selectedProducts };
    _newReturnOrder.returnOrderDetails.map((_prod, i) => {
      if (
        _prod.productId === product.productId &&
        _prod?.batchNo === product?.batchNo
      ) {
        _prod.unitPrice = e.target.value ?? 0;
        _prod.productAmount = _prod.returnQty * _prod.unitPrice;
        if (_prod.vatPercentage) {
          _prod.vat = (_prod.productAmount / 100) * _prod.vatPercentage;
          _prod.totalAmount = _prod.productAmount + _prod.vat;
        }
      }
    });
    setSelectedProducts(_newReturnOrder);
  };
  const handleProductVatPrChange = (e: any, product: any) => {
    let _newReturnOrder = { ...selectedProducts };
    _newReturnOrder.returnOrderDetails.map((_prod, i) => {
      if (
        _prod.productId === product.productId &&
        _prod?.batchNo === product?.batchNo
      ) {
        _prod.vatPercentage = e.target.value ? e.target.value : 0;
        _prod.vat = (_prod.productAmount / 100) * _prod.vatPercentage;
        _prod.totalAmount = _prod.productAmount + _prod.vat;
      }
    });
    setSelectedProducts(_newReturnOrder);
  };

  const deleteProductInPo = (product: any) => {
    let _newReturnOrder = { ...selectedProducts };
    _.remove(_newReturnOrder.returnOrderDetails, function (n) {
      return (
        n.productId === product.productId && n?.batchNo === product?.batchNo
      );
    });
    setSelectedProducts(_newReturnOrder);
  };

  const handleFormChange = (type: string, value: any, otherVal: any = null) => {
    let _newReturnOrder = { ...selectedProducts };
    if (type === "soNum") {
      let data = value?.split(" | ");
      if (data?.length >= 3) {
        _newReturnOrder["soNum"] = data[0];
        _newReturnOrder["deliveryNum"] = data[1];
        _newReturnOrder["customerId"] = customersList?.find(
          (item) => item?.custName === data[2]
        )?.custId;
        _newReturnOrder["customerName"] = data[2];
        updateAddress(_newReturnOrder);
        getROProductsList(data[0], data[1]);
        _newReturnOrder.returnOrderDetails = [];
      }
    } else if (type === "deliveryAddress") {
      _newReturnOrder[type] = value;
      _newReturnOrder.addressId = otherVal;
    } else {
      _newReturnOrder[type] = value;
    }

    setSelectedProducts(_newReturnOrder);
  };

  const updateAddress = (_newOrder: IReturnOrders, addressId = null) => {
    let customerId = _newOrder?.customerId;
    const matchedCustomer = customersList.find(
      (customer) => Number(customer?.custId) === Number(customerId)
    );

    if (matchedCustomer) {
      let data = [];
      matchedCustomer?.custAddresses?.map((address) => {
        let item = { label: "", value: "", addressId: 0 };
        item.label = `${address?.address1}, ${address?.address2}`;
        item.value = `${address.address1},${address.address2},${address.city},${address.country},${address.postCode}`;
        item.addressId = address?.custAddressId;
        data.push(item);
        if (addressId !== null && addressId === address?.custAddressId) {
          setSelectedProducts({
            ..._newOrder,
            deliveryAddress: item?.value,
          });
        }
      });

      // Update the state with the customer addresses
      setCurrentCustomerAddress(data);
    }
  };

  const calculateTotalTaxVal = () => {
    let _returnOrderProducts = [...selectedProducts.returnOrderDetails];
    let totalTax = 0;
    _returnOrderProducts.map((_prod, i) => {
      totalTax += _prod.vat ?? 0;
    });
    return parseFloat(totalTax.toFixed(2));
  };
  const calculateTotalRefundVal = () => {
    let _returnOrderProducts = [...selectedProducts.returnOrderDetails];
    let totalAmt = 0;
    _returnOrderProducts.map((_prod, i) => {
      totalAmt += _prod.totalAmount ?? 0;
    });
    totalAmt = totalAmt - (selectedProducts?.carriageAmount ?? 0);
    if (totalAmt < 0) {
      totalAmt = 0;
    }
    return parseFloat(totalAmt.toFixed(2));
  };

  const calculateTotalAmountVal = () => {
    let _returnOrderProducts = [...selectedProducts.returnOrderDetails];
    let totalAmt = 0;
    _returnOrderProducts.map((_prod, i) => {
      totalAmt += _prod.totalAmount ?? 0;
    });
    return parseFloat(totalAmt.toFixed(2));
  };

  const handleCreateRo = () => {
    let obj = {
      salesOrder: selectedProducts?.soNum,
      returnDate: selectedProducts?.roCreatedDate,
      address: selectedProducts?.deliveryAddress,
      status: selectedProducts?.status,
      reason: selectedProducts?.returnReason,
      carriage: selectedProducts?.carriageAmount,
    };
    let isErrors = validator(obj, VALIDATOR);
    setError(isErrors);

    if (!_.isEmpty(isErrors)) {
      return;
    }

    let _newReturnOrder = { ...selectedProducts };
    _newReturnOrder.vatAmount = calculateTotalTaxVal();
    _newReturnOrder.totalAmount = calculateTotalAmountVal();
    _newReturnOrder.returnAmount = calculateTotalRefundVal();
    _newReturnOrder.returnOrderNum = nextRO ? nextRO : "";
    _newReturnOrder.documentId =
      _newReturnOrder?.documentId ?? _newReturnOrder.returnOrderNum;
    _newReturnOrder.addressId = selectedProducts?.addressId;

    _newReturnOrder.roCreatedDate = moment(
      selectedProducts.roCreatedDate
    ).format("yyyy-MM-DDThh:mm:ss.SSSS");
    _newReturnOrder.createdDate = moment(selectedProducts.createdDate).format(
      "yyyy-MM-DDThh:mm:ss.SSSS"
    );
    _newReturnOrder.modifiedDate = moment(selectedProducts.modifiedDate).format(
      "yyyy-MM-DDThh:mm:ss.SSSS"
    );

    let poProductsList = _newReturnOrder.returnOrderDetails;
    let newPoProductsList = [];
    poProductsList.map((pr) => {
      let plItem = {
        prodId: pr.productId,
        prodCatId: pr.prodCategory,
        returnQty: pr.returnQty,
        unitPrice: pr.unitPrice,
        productAmount: pr.productAmount,
        vat: pr.vatPercentage,
        taxAmount: pr.vat,
        totalAmount: pr.totalAmount,
        batchNo: pr?.batchNo,
        batchExpiry: pr?.batchExpiry,
        dispatchQty: pr?.shippedQty,
        id: pr?.id,
        returnOrderId: pr?.returnOrderId,
      };
      if (modeType === FORM_MODES.Create) {
        delete plItem.id;
        delete plItem.returnOrderId;
      }
      newPoProductsList.push(plItem);
    });
    _newReturnOrder.returnOrderDetails = newPoProductsList;
    if (_newReturnOrder.returnOrderDetails.length > 0) {
      if (_newReturnOrder.totalAmount > 0) {
        if (modeType === FORM_MODES.Edit) {
          updateReturnOrders(_newReturnOrder, () => {
            navigate(RoutingConstants.returnorders);
          });
        } else {
          createNewReturnOrders(_newReturnOrder, () => {
            navigate(RoutingConstants.returnorders);
          });
        }
      } else {
        toast.error("Please fill mandatory fields");
      }
    } else {
      toast.error("Please add products to Return order");
    }
  };

  return (
    <>
      <div className="content-header">
        <div className="d-flex align-items-center">
          <div className="me-auto">
            <div className="d-inline-block align-items-center">
              <nav>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.dashboard}
                      title={LabelConstants.Dashboard}
                    >
                      <i className="mdi mdi-home-outline"></i>
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive ? "active" : ""} activeLink`
                      }
                      to={RoutingConstants.returnorders}
                      title={LabelConstants.ReturnOrders}
                    >
                      {LabelConstants.ReturnOrders}
                    </NavLink>
                  </li>
                  <li
                    className="breadcrumb-item active"
                    aria-current="page"
                  >{`${LabelConstants.Create} ${LabelConstants.ReturnOrders}`}</li>
                </ol>
              </nav>
            </div>
          </div>
          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={() => handleCreateRo()}
            disabled={modeType === FORM_MODES.View}
          >
            Save changes
          </button>
        </div>
      </div>
      <section className="content">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="box">
              <div className="box-header  bg-primary d-flex justify-content-between">
                <h4 className="box-title">Products </h4>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-bs-toggle="modal"
                  data-bs-target="#modal-right"
                  disabled={modeType === FORM_MODES.View}
                >
                  Add Product
                </button>
              </div>
              <div className="box-body">
                {/* products Table */}
                <div className="table-responsive">
                  <table
                    className="table product-overview table-bordered text-center"
                    style={
                      modeType === FORM_MODES.View
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <thead className="table-primary">
                      <tr>
                        <th>Product Category</th>
                        <th>Product Name</th>
                        <th>Batch Exp.</th>
                        <th>Batch No.</th>
                        <th>Shipped Qty.</th>
                        <th>Returned Qty.</th>
                        <th>Price/ unit</th>
                        <th className="text-align:center">VAT(%)</th>
                        <th className="text-align:center">Tax</th>
                        <th className="text-align:center">Amount</th>
                        <th className="text-align:center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedProducts?.returnOrderDetails?.length > 0 ? (
                        selectedProducts.returnOrderDetails.map(
                          (product: IROProductDetails, i: number) => (
                            <tr key={i}>
                              <td className="text-start">
                                {getProductCatNameById(
                                  product.prodCategory,
                                  productCateList
                                )}
                              </td>
                              <td className="text-start">
                                {product.productCode} - {product.productName}
                              </td>
                              <td className="text-align:center">
                                {product?.batchExpiry}
                              </td>
                              <td className="text-align:center">
                                {product?.batchNo}
                              </td>
                              <td className="text-align:center">
                                {product?.shippedQty}
                              </td>
                              <td className="fw-900 w-150">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={product?.returnQty ?? 0}
                                  onChange={(e) =>
                                    handleProductQtyChange(e, product)
                                  }
                                />
                              </td>
                              <td className="fw-900 w-150">
                                <input
                                  type="number"
                                  className="form-control"
                                  min={0}
                                  value={product?.unitPrice ?? 0}
                                  onChange={(e) =>
                                    handleProductPriceChange(e, product)
                                  }
                                />
                              </td>
                              <td className="w-90">
                                <input
                                  type="text"
                                  className="form-control"
                                  min={0}
                                  value={
                                    product.vatPercentage
                                      ? product.vatPercentage
                                      : 0
                                  }
                                  onChange={(e) =>
                                    handleProductVatPrChange(e, product)
                                  }
                                />
                              </td>
                              <td>
                                {product.vat ? product.vat.toFixed(2) : 0}
                              </td>
                              <td>
                                {product.totalAmount
                                  ? product.totalAmount.toFixed(2)
                                  : 0}
                              </td>
                              <td align="center">
                                <div className="d-flex justify-content-center">
                                  <span
                                    onClick={() => deleteProductInPo(product)}
                                  >
                                    <i
                                      className="fa fa-trash text-danger fs-3"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan={9} align="center">
                            <br />
                            <p>No products added to return orders</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => navigate(RoutingConstants.returnorders)}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary float-end"
                    onClick={() => handleCreateRo()}
                    disabled={modeType === FORM_MODES.View}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* Return order container*/}
          <div className="col-12 col-lg-4">
            <div className="box">
              <div className="box-header bg-info p-3">
                <h4 className="box-title">Sales Return Details</h4>
                <p className="p-0 m-0">
                  #{LabelConstants.RONumber} : {nextRO ? nextRO : "N/A"}
                </p>
              </div>
              <div className="box-body">
                <div className="row">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.SalesOrder}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-8"
                    style={
                      modeType === FORM_MODES.View ||
                      modeType === FORM_MODES.Edit
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <Select
                      value={salesOrderList?.filter(
                        (option) =>
                          option.value ===
                          `${selectedProducts?.soNum} | ${selectedProducts?.deliveryNum}`
                      )}
                      options={salesOrderList}
                      isSearchable={true}
                      onChange={(e) => {
                        handleFormChange("soNum", e.label);
                      }}
                    />
                    {error?.salesOrder && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>
                          {error?.salesOrder}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.ReturnData}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-8"
                    style={
                      modeType === FORM_MODES.View
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <DatePicker
                      className="form-control bg-white"
                      value={getDateIfValid(selectedProducts.roCreatedDate)}
                      format="DD-MM-YYYY"
                      onChange={(e, ds) => {
                        let date = e?.format("YYYY-MM-DD");
                        handleFormChange("roCreatedDate", date);
                      }}
                    />
                    {error?.returnDate && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>
                          {error?.returnDate}
                        </span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.CustomerName}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      className="form-control"
                      value={selectedProducts?.customerName ?? ""}
                      disabled
                    />
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label">
                      {LabelConstants.ReturnAddress}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-8"
                    style={
                      modeType === FORM_MODES.View
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <Select
                      options={currentCustomerAddress}
                      value={currentCustomerAddress?.filter(
                        (option) =>
                          option.value === selectedProducts?.deliveryAddress
                      )}
                      isSearchable={true}
                      onChange={(e) => {
                        let salesorder: IReturnsSalesOrderDetails[] =
                          salesOrderList?.filter(
                            (item) => item.label === e.label
                          );
                        handleFormChange(
                          "deliveryAddress",
                          e.value,
                          e.addressId
                        );
                      }}
                    />
                    {error?.address && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>{error?.address}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Status}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-8"
                    style={
                      modeType === FORM_MODES.View
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <select
                      className="form-select"
                      value={selectedProducts?.status}
                      onChange={(e) => {
                        handleFormChange("status", e.target.value);
                      }}
                    >
                      <option>--</option>
                      {[...PurchaseStatuses, "CANCELLED"].map(
                        (_status: string, _statusIdx: number) => {
                          return (
                            (_status === "DRAFT" ||
                              _status === "SUBMITTED" ||
                              (["CLOSED", "CANCELLED"].includes(_status) &&
                                modeType === FORM_MODES.View)) && (
                              <option
                                key={`statusOption${_statusIdx}`}
                                value={_status}
                              >
                                {_status}
                              </option>
                            )
                          );
                        }
                      )}
                    </select>
                    {error?.status && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>{error?.status}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Reason}
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    className="col-8"
                    style={
                      modeType === FORM_MODES.View
                        ? { pointerEvents: "none" }
                        : {}
                    }
                  >
                    <textarea
                      value={selectedProducts?.returnReason}
                      onChange={(e) =>
                        handleFormChange("returnReason", e.target.value)
                      }
                      rows={3}
                      className="form-control"
                      placeholder={LabelConstants.Reason}
                    />
                    {error?.reason && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>{error?.reason}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      {LabelConstants.Attachment}
                    </label>
                  </div>
                  <div className="col-12 col-lg-8">
                    <div
                      className={`form-group d-flex flex-column flex-lg-row col-12 h-auto`}
                      style={
                        modeType === FORM_MODES.View
                          ? { pointerEvents: "none" }
                          : {}
                      }
                    >
                      <Dropzone
                        onDrop={(acceptedFiles) =>
                          handleFileUpload(acceptedFiles)
                        }
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div
                            {...getRootProps()}
                            className="dropzone rounded d-flex justify-content-center align-items-center"
                            style={{
                              cursor: "pointer",
                              height: "calc(100% - 31px) !important",
                            }}
                          >
                            <input {...getInputProps()} />
                            <h5 className="text-center">
                              Drag 'n' drop some files here
                              <br /> or click to select files
                            </h5>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                    {(uploadedFiles?.length > 0 ||
                      viewFilesList?.length > 0) && (
                      <div className={`form-group col-12 h-auto`}>
                        <label className="form-label" htmlFor="comments">
                          {LabelConstants.Preview}
                        </label>
                        <ul className="list-group">
                          {viewFilesList.map(
                            (_file: IDocuments, _fileIdx: number) => (
                              <li
                                key={_file.documentId}
                                className="list-group-item d-flex justify-content-between align-items-center"
                              >
                                <span
                                  className="badge badge-pill"
                                  role="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#showPreviewFile"
                                  onClick={() => {
                                    setactiveURL(
                                      `${
                                        LabelConstants.urlHost
                                      }${_file.documentPath.substring(
                                        _file.documentPath.indexOf("/images")
                                      )}`
                                    );
                                  }}
                                >
                                  <i className="fa fa-file-o text-primary fs-16"></i>
                                </span>
                                <span>{_file.documentName}</span>
                                <span
                                  className="badge badge-pill"
                                  style={
                                    modeType === FORM_MODES.View
                                      ? { pointerEvents: "none" }
                                      : {}
                                  }
                                  onClick={() =>
                                    handleRemoveOldFile(_file.documentId)
                                  }
                                >
                                  <i className="fa fa-trash text-danger"></i>
                                </span>
                              </li>
                            )
                          )}
                          {uploadedFiles.map((_file: any, _fileIdx: number) => (
                            <li className="list-group-item d-flex justify-content-between align-items-center">
                              <span
                                className="badge badge-pill"
                                role="button"
                                data-bs-toggle="modal"
                                data-bs-target="#showPreviewFile"
                                onClick={() => setactiveURL(_file.preview)}
                              >
                                <i className="fa fa-file-o text-primary fs-16"></i>
                              </span>
                              <span>{_file.name}</span>
                              <span
                                className="badge badge-pill"
                                onClick={() => handleRemoveFile(_fileIdx)}
                              >
                                <i className="fa fa-trash text-danger"></i>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-20">
                  <div className="col-4">
                    <label className="form-label" htmlFor="goodsReceiptNumber">
                      Carriage Amount
                      <span className="text-danger">*</span>
                    </label>
                  </div>
                  <div className="col-8">
                    <input
                      type="number"
                      min={0}
                      disabled
                      className="form-control"
                      value={selectedProducts?.carriageAmount ?? 0}
                      onChange={(e) => {
                        let value = getNumberForString(e.target.value);
                        if (value === ERROR) value = 0;
                        handleFormChange("carriageAmount", value);
                      }}
                    />
                    {error?.carriage && (
                      <label className="form-label mt-10">
                        <span style={{ color: "red" }}>{error?.carriage}</span>
                      </label>
                    )}
                  </div>
                </div>
                <div className=" d-flex mt-20">
                  <div className="col-4">
                    <h5 className="fw-bold">Tax</h5>
                  </div>
                  <div className="col-8">
                    <p className="text-end fw-700 h6">
                      {LabelConstants.DollarSign}
                      {selectedProducts?.returnOrderDetails?.length > 0
                        ? calculateTotalTaxVal()
                        : 0}
                    </p>
                  </div>
                </div>
                <div className="d-flex mt-20">
                  <div className="col-4">
                    <h5 className="bt-1 fw-bold">Refund Amount</h5>
                  </div>
                  <div className="col-8">
                    <p className="bt-1 text-end fw-900 fs-18">
                      {LabelConstants.DollarSign}
                      {selectedProducts?.returnOrderDetails?.length > 0
                        ? calculateTotalRefundVal()
                        : 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Products list modal */}
      <div className="modal modal-right fade overflow-auto" id="modal-right">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Products List</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <input
                type="search"
                placeholder="Search Product"
                value={searchProduct}
                className="form-control"
                onChange={onClickSearch}
              />
              <br />
              <ul className="list-group">
                {filteredProducts?.length > 0 &&
                  [
                    ...new Map(
                      filteredProducts.map((item) => [
                        item["productCode"],
                        item,
                      ])
                    ).values(),
                  ].map((product) => (
                    <li
                      className={`list-group-item ${
                        selectedProducts.returnOrderDetails.some(
                          (p) => p.productId === product.productId
                        ) && "d-none"
                      }`}
                      key={product.productId}
                    >
                      <input
                        type="checkbox"
                        id={product.productId.toString()}
                        checked={selectedProducts.returnOrderDetails.some(
                          (p) => p.productId === product.productId
                        )} // Set checked based on presence in selectedProducts
                        onChange={() => {
                          handleCheckboxClick(product);
                        }} // Set checked based on presence in selectedProducts
                      />
                      <label htmlFor={product.productId.toString()}>
                        <b>{product.productCode}</b> - {product.productName}
                      </label>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-primary float-end"
                data-bs-dismiss="modal"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="showPreviewFile" tabIndex={-1}>
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">File Preview</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => {
                  setactiveURL("");
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body" style={{ height: "90vh" }}>
              <iframe src={activeURL} width={"100%"} height={"100%"} />
            </div>
            <div className="modal-footer modal-footer-uniform">
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
                onClick={() => {
                  setactiveURL("");
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ReturnOrdersFormcomponent;
