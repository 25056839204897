import React from "react";
import Logo from "../../assets/images/consult_veda_logo.png";

export default function PrintHeader(props) {
  const {
    leftItem,
    centerText,
    leftItemSize = "col-2",
    centerItemSize = "col-8",
  } = props;
  return (
    <div className="col-12 table-responsive ">
      <table className="table b-0 border-white">
        <thead style={{ borderColor: '#2f579a' }}>
          <tr style={{ borderColor: '#2f579a' }}>
            {/* <th className={`${leftItemSize} text-start`}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {leftItem}
              </div>
            </th> */}
            <th className={centerItemSize}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  id="logo-image"
                  src={Logo}
                  alt="logo"
                  height={30}
                  width={120}
                  style={{ opacity: 0 }}
                />
                <h2 className="text-bold" style={{ color: '#2f579a' }}>{centerText}</h2>
              </div>
            </th>
            <th className="col-4">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  id="logo-image"
                  src={Logo}
                  alt="logo"
                  height={80}
                  width={120}
                />
                <h5 className="text-bold" style={{ margin: 0 }}>
                  MEDLEY PHARMA LTD.
                </h5>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={3}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <p style={{ fontSize: 9, margin: 0 }}>
                  Company Registration : 09196622
                </p>
                <p style={{ fontSize: 9, margin: 0 }}>
                  Registered Office : Unit 2A, Olympic Way, Sefton Business
                  Park,Liverpool L30 1RD
                </p>
                <p style={{ fontSize: 9, margin: 0 }}>VAT : 203 0049 75</p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
