export const LabelConstants = {
  // BreadCrumb / Modules
  Dashboard: "Dashboard",
  Warehouse: "Warehouse",
  Franchises: "Franchises",
  Profile: "Profile",
  Settings: "Settings",
  SignOff: "Sign Off",

  UserPermissionsAndAccess: "User Permissions & Access",

  Master: "Master",
  Reports: "Reports",
  Supplier: "Supplier",
  PO: "abc",
  SO: "SO",
  OrderPicking: "Order Picking",
  OrderIn: "Order In",
  OrderOut: "Order Out",
  BatchManagement: "Batch Management",
  StockTransfer: "Stock Transfer",

  Stock: "Stock",
  Stock_Planning: "Stock Planning",
  Goods_Movement: "Goods Movement",
  Product_Movement: "Product Movement",
  Pending_SO: "Pending SO",
  Dispatch: "Dispatch",

  CreateDeliveryOrder: "Create Delivery Order",
  ShipQty: "Ship QTY",
  ShipDate: "Ship Date",
  Attachment: "Attachment",

  //sampling
  SamplingInfo: "Sampling info",
  desc: "Desc",
  SampligNumber: "Sampling number",
  ProductCode: "Product Code",
  Strength: "Strength",
  BatchQuantity: "Batch Quantity",
  SampleQuantity: "Sample Quantity",
  FinalQuantity: "Final Quantity",
  QualityOrderStatus: "Quality Order Status",
  Sampling: "Sampling",
  BatchNum: "Batch Number",
  PalletRefNum: "Pallet Ref Number",
  SamplingQty: "Sampling Qty",
  FinalQty: "Final Qty",

  Returns: "Returns",
  ReturnOrders: "Return Orders",
  RONumber: "RO Number",
  ReturnData: "Return Date",
  ReturnAddress: "Return Address",
  Reason: "Reason",

  Customer: "Customer",
  Transporter: "Transporter",
  Employee: "Employee",
  Products: "Product",

  CustomersReport: "Customers",
  TransportersReport: "Transporters",
  SuppliersReport: "Suppliers",
  ProductsReport: "Products",

  //Sales order
  SalesOrder: "Sales Order",
  SoNumber: "SO Number",
  DeliveryAddress: "Delivery Address",

  //Delivery Order
  DeliveryOrder: "Delivery Order",
  FormTitle: "Shipment",
  BookingReference: "Booking Reference",
  TrackingNumber: "Tracking Number",
  EstimatedDeliveryDate: "Est. Delivery Date",
  DataLogger: "DataLogger",
  PickingDate: "Picking Date",

  //purchase
  PurchaseOrderInfo: "PurchaseOrderInfo",
  PurchaseOrder: "Purchase Order",
  vendorName: "Vendor Name",
  PoNumber: "PO Number",
  ToNumber: "TO Number",
  ExpectedDeliveryDate: "Exp. Delivery Date",
  items: "Items",
  totalAmount: "Total Amount",
  totalTaxAmount: "Total Tax Amount",
  referenceNumber: "Ref. Number",
  poStatus: "Po Status",
  Reference: "Reference",
  POCreatedDate: "POCreated Date",
  Carriage: "Carriage",

  GoodsMovement: "Goods Movement",
  WarehouseToWarehouse: "W2W",
  LocationToLocation: "L2L",
  BatchTraceablility: "Batch Traceablility",
  GRN: "GRN",
  GrnDetails: "GRN Details",
  GrnOrderInfo: "GRN Order Info",
  ListGRN: "List GRN",
  GoodsReceiptNote: "Goods Receipt Note",
  GoodsReceiptNumber: "Goods Receipt Number",
  CreateGRN: "Create GRN",
  EditGRN: "Edit GRN",
  ViewGRN: "View GRN",
  UpdateStatus: "Update Status",
  AddListOption: (title: string) => `Add ${title}`,

  // CRUDs
  Menu: "Menu",
  Create: "Create",
  Edit: "Edit",
  Delete: "Delete",
  New: "New",
  Details: "Details",
  Description: "Description",
  Add: "Add",
  // PLaceholders
  Search: "Search",
  Clear: "Clear",
  All: "All",

  // Error Messages
  DeleteNotAvailableLater:
    "Can not delete at the moment. Please try again later.",
  FileUploadCountExceeded:
    "Files upload limit exceeded! \n You can only upload up to 5 files.",
  // Transporter
  TransporterName: "Transporter Name",
  TransporterInfo: "Transporter Info",
  TransporterAddress: "Transporter Address",
  CommunicationDetails: "Communication Details",
  ValidFrom: "Valid From",
  ID: "ID",
  ValidUntil: "Valid Until",
  Status: "Status",
  ModifiedDate: "Modified Date",
  DeliveredDate: "Delivered Date",
  Address1: "Address 1",
  Address2: "Address 2",
  City: "City",
  Country: "Country",
  County: "County",
  Contact: "Contact",
  Name: "Name",
  Phone: "Phone",
  Email: "Email",
  Validity: "Validity",
  PostCode: "Post Code",
  ValidTo: "Valid To",
  Comments: "Comments",
  Files: "Files",
  Preview: "Preview",
  ContactName: "Contact Name",
  Designation: "Designation",

  //Supliers
  SupplierName: "Supplier Name",
  SupplierType: "Supplier Type",
  SupplierAddresses: "Supplier Addresses",
  SupplierInfo: "Supplier Info",

  //Product
  productCode: "Product Code",
  ProductLicenseNo: "Product License No",
  // POCreatedDate: 'POCreated Date',
  Manufacturer: "Manufacturer",
  ProductManufacturer: "Product Manufacturer",
  Category: "Category",
  Info: "Info",
  PackSize: "Pack Size",
  SelectStatus: "Select Status",
  DosageForm: "Dosage Form",
  GTINBarcode: "GTINBarcode",
  PIPCode: "PIP Code",
  MinStockQty: "Min Stock Qty",
  ProductInfo: "Product Info",
  ProductName: "Product Name",
  ProductCategory: "Product Category",
  SelectProductCategory: "Select Product Category",
  SelectDosageForm: "Select Dosage Form",
  prodCategory: "Category",
  dosageForm: "Dosage Form",

  //employee
  EmployeeName: "Employee Name",
  Gender: "Gender",
  DateofBirth: "Date of Birth",
  DateofResignation: "Date of Resignation",
  DateofJoining: "Date of Joining",
  EmployeeInfo: "Employee Info",
  Username: "Username",
  EmployeeAddresses: "Employee Addresses",
  Password: "Password",
  Passcode: "Passcode",

  //Customer
  CustomerName: "Customer Name",
  CustomerType: "Customer Type",
  CustomerInfo: "Customer Info",
  CustomerAddress: "Customer Address",
  Type: "Type",
  Address: "Address",

  Others: "Others",

  PoNum: "puchase Order Number (PO Number)",
  supplierId: "supplierId",
  refNumber: "Reference Number",

  //GRN
  GoodsReceipt: "Goods Receipt",
  GoodsReceiptType: "Goods Receipt Type",
  Number: "Order Number",
  PO_NUMBER: "Purchase Order Number",
  TO_Number: "Transfer Order Number",
  RO_Number: "Return Order Number",
  TransferOrderNumber: "Transfer Order Number",
  ReturnOrderNumber: "Return Order",
  DateOfReceipt: "Date of Receipt",
  SupplierNameAddressCheck: "Supp. Name/Add. Checked?",
  ContainerSealed: "Is Container Sealed?",
  ContainerSealNumber: "Container Seal Number",
  BatchDetails: "Batch Details",
  BatchNumber: "Batch Number",
  EnterValidPrefix: "Enter Valid ",
  ExpiryDate: "Exp. Date",
  BatchStatus: "Batch Status",
  ReceivedQuantity: "Rcv. Qty",
  NoOfPallets: "No. of Pallets",
  QuantityOnPallet: "Qty. on Pallet",
  PalletReferenceNumber: "Ref. Number",
  GoodsReceiptStatus: "Goods Receipt Status",
  CreatedDate: "Created Date",
  TrnasferDate: "Transfer Date",
  CreatedBy: "Created By",
  ModifiedBy: "Modified By",
  WareHouseName: "WareHouse Name",
  IsSamplingRequired: "Is Sampling Required?",
  ProductDetails: "Product Details",
  Summary: "Summary",
  Cancel: "Cancel",
  Save: "Save",
  Back: "Back",
  AddEditProducts: "Add/Edit Products",
  SelectProducts: "Select Products",
  Action: "Action",

  //GRN_BATCH
  Batch: "Batch",
  CreateBatch: "Create Batch",
  AssignPallet: "Assign Pallet",
  Qty: "Qty",
  Automatic: "Auto",
  Manual: "Manual",
  Yes: "Yes",
  No: "No",
  EditBatch: "Edit Batch",
  ViewBatch: "View Batch",

  // Warehouse
  WarehouseInfo: "Warehouse Info",
  WarehouseName: "Warehouse Name",
  ClientName: "Client Name",
  WarehouseType: "Warehouse Type",
  WarehouseScope: "Warehouse Scope",
  Locations: "Locations",
  Aisle: "Aisle",
  TotalReceived: "Total Rcvd.",
  TotalQty: "Total Qty",
  ReceivedQty: "Recv. Qty",
  Asile: "Aisle",
  Bay: "Bay",
  Shelf: "Shelf",

  urlHost: window.origin,
  euroSign: "£",
  DollarSign: "$"
};
