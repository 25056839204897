export const MAX_PAGE_RECORD_SIZE = 8;
//Dev
export const ApiBaseHost = window.location.origin;
//Local
// export const ApiBaseHost = "http://3.8.136.9";
// export const ApiBaseHost = "https://dev.medleypharma.co.uk";
export const WarehouseApiBase = `${ApiBaseHost}/wms-api/`;
export const OrdersApiBase = `${ApiBaseHost}/orders-api/`;
export const ReportsApiBase = `${ApiBaseHost}/api/`;

// Authentication
export const Authenticate = "employees/authenticate";
export const resetPassword = "employees/forgetPassword";
// export const changePassword = "employees/resetPassword";
export const updatePassword = "employees/updatePassword";
export const changePassword = "employees/changePassword";

//Reports
export const POReports = "report/purchase/order";
export const StockReports = "report/stock";
export const PlanningReports = "report/stock/planing";
export const GoodsMovementReports = "report/goodsMovementReport";
export const GoodsReceiptReports = "report/goods/receipts";
export const BatchTraceablilityReports = "report/batch/traceability";
export const ProductMovementReports = "report/productMovement";
export const DispatchReports = "report/dispatchReport";
export const PendingSOReports = "report/pendingSoReport";
export const SupplierReport = "master/report/supplier";
export const CustomerReport = "master/report/customer";
export const TransporterReport = "master/report/transporter";
export const ProductsReport = "master/report/product";

// Master
export const DosageFormList = "products/dosage/list";
export const SaveDosageForm = "products/dosage/create";
export const ProductCategoryList = "products/category/list";
export const SaveProductCategory = "products/category/create";

// Documents
export const DocumentsList = (userId: string) => `documents/list/${userId}`;
export const DocumentsUpload = "documents/upload";
export const DocumentsDelete = (docId: number) =>
  `documents/deleteDocument/${docId}`;

// Products
export const ProductList = "products/getAll";
export const ProductSave = "products/create";
export const ProductUpdateById = (id: number) => `products/update/${id}`;
export const ProductStatusUpdate = "products/update/status";
export const searchProductsByCriteria = `products/search`;

// GRN
export const searchByCriteria = (pageNo: number) =>
  `goodsReceipts/search?pageNo=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const OrdersById = (id: string) =>
  `goodsReceipts/getPurchaseOrderByPoNum/${id}`;
export const AllOrdersById = (id: string) => `purchaseOrder/fetchOrder/${id}`;
export const RoOrdersById = (id: string) =>
  `return/fetchByReturnOrderNum/${id}`;
export const ToOrdersById = (id: string) =>
  `transfer/order/fetchByTransferOrderNum/${id}`;
export const CreateGRN = "goodsReceipts/create";
export const UpdateGRN = (id: number) => `goodsReceipts/update/${id}`;
export const GRNList = (pageNo: number, id: string = "ASC") =>
  `goodsReceipts/list?pageNo=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const NextGRNReceiptNumber = (type: string) =>
  `sequence/getNextSequence?prefix=${type}`;
export const GetEligiblePOsList =
  "purchaseOrder/fetchPONumsByStatus?status=SUBMITTED,PART_FILL";
export const GetEligibleTOsList =
  "transfer/order/getTransferOrdersByStatus?status=DISPATCHED";
export const GetAllTOsList =
  "transfer/order/getTransferOrdersByStatus?status=DISPATCHED,DELIVERED";
export const GetROsList = "return/fetchReturnsOrderByStatus?status=SUBMITTED";
export const GetAllPOsList =
  "purchaseOrder/fetchPONumsByStatus?status=DRAFT,SUBMITTED,PART_FILL,FULL_FILL,CANCEL,CLOSED";
export const GRNStatusUpdate = "goodsReceipts/updateStatus";

// Supplier
export const SupplierList = "suppliers/getAll";
export const SupplierAllList = "suppliers/list";
export const SupplierSave = "suppliers/create";
export const SupplierUpdateById = (id: number) => `suppliers/update/${id}`;
export const SupplierStatusUpdate = "suppliers/update/status";

// Settings
export const FetchUserAccessPermissionApiByRole = (roleCode: string) =>
  `access/fetchAccessByRoleCode/${roleCode}`;
export const UpdateUserAccessPermissionApiByRole = `access/create`;
export const EmployeeRoles =
  "role/search?pageNo=0&pageSize=500&sort=id&order=DESC";

// Customer
export const CustomerList = "customers/getAll";
export const CustomersAllList = "customers/list";
export const CustomerSave = "customers/create";
export const CustomerUpdateById = (id: number) => `customers/update/${id}`;
export const CustomerStatusUpdate = "customers/update/status";
export const searchCustomersByCriteria = `customers/search`;

// Employee
export const EmployeeList = "employees/getAll";
export const EmployeeSave = "employees/register";
export const EmployeeUpdateById = (id: number) => `employees/update/${id}`;
export const EmployeeStatusUpdate = "employees/update/status";

// Transporter
export const TransporterList = "transporters/getAll";
export const TransportersAllList = "transporters/list";
export const TransporterSave = "transporters/create";
export const TransporterUpdateById = (id: number) =>
  `transporters/update/${id}`;
export const TransporterStatusUpdate = "transporters/update/status";
export const searchTranportByCriteria = `transporters/search`;

// Warehouse
export const WarehouseList = "warehouse/getAll";
export const WarehouseSave = "warehouse/create";
export const LocationTransfer = "/inter/transfer/create";
export const WarehouseUpdateById = (id: number) => `warehouse/update/${id}`;
export const WarehouseLayoutList = (id: number) =>
  `/warehouse/aisle/fetchByWarehouseId/${id}`;
export const getWarehousStatsById = (id: number) =>
  `/goodsReceipts/getWarehouseDetails/${id}`;
export const WarehouseStatusUpdate = "warehouse/update/status";
export const WarehouseLocationSave = "warehouse/aisle/create";
export const WarehouseLocationEdit = (id: number) =>
  `warehouse/locations/update/${id}`;
export const GetLocationDtlsById = (id: number) =>
  `goodsReceipts/getProductInLocation/${id}`;
export const updateLocationsArray = "warehouse/locations/updateLocationsStatus";
// export const GetLocationDtlsById = "warehouse/getAll";

//PurchaseOrder
export const purchaseOrderList = (pageNo: number) =>
  `purchaseOrder/list?pageNo=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const purchaseOrderSave = "purchaseOrder/create";
export const purchaseOrderUpdateById = (id: number) =>
  `purchaseOrder/update/${id}`;
export const purchaseOrderUpdate = "purchaseOrder/updateStatus";
export const getNextPoNumber = "sequence/getNextSequence?prefix=PO";
export const poSearch = (pageNo: number) =>
  `purchaseOrder/search?pageNo=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;

//ReturnOrder
export const returnOrdersList = (pageNo: number) =>
  `return/list?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const returnOrderSave = "return/create";
export const returnOrderUpdateById = (id: number) => `/return/update/${id}`;
export const returnOrderUpdate = "return/update/status";
export const getNextRoNumber = "sequence/getNextSequence?prefix=RO";
export const recentlyDeliveredOrders =
  "return/RecentlyDeliveredOrders?status=DELIVERED";
export const roSearch = (pageNo: number) =>
  `return/search?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const returnOrderPriceDetails = (soNum: string, doNum: string) =>
  `return/getDeliveredProducts/PriceDetails?saleOrderNum=${soNum}&deliveryOrderNum=${doNum}`;

//SalerOrder
export const salesOrderList = (pageNo: number, maxSize: number) =>
  `saleOrder/list?pageNum=${pageNo}&pageSize=${maxSize}`;
export const salesOrderSave = "saleOrder/create";
export const salesOrderUpdateById = (id: number) => `saleOrder/update/${id}`;
export const salesOrderUpdate = "saleOrder/update/status";
export const getNextSalesOrderNumber = "sequence/fetch/next/saleOrder/num";
export const stockInHand = (id: string, quantity: number) =>
  `saleOrder/checkHandsOnStock?prodId=${id}&quantity=${quantity}`;
export const searchSOByCriteria = (pageNo: number, maxSize: number) =>
  `saleOrder/search?pageNum=${pageNo}&pageSize=${maxSize}`;
export const getSalesOrderBySoNum = (sonum: string) =>
  `saleOrder/fetch/saleOrderBySoNum/${sonum}`;
export const searchSamplingByCriteria = (pageNo: number, maxSize: number) =>
  `sampling/search?pageNum=${pageNo}&pageSize=${maxSize}`;

//Delivery
export const deliveryOrderList = (pageNo: number) =>
  `delivery/list?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const deliveryOrderSave = "delivery/create";
export const deliveryOrderUpdateById = (id: number) => `delivery/update/${id}`;
export const deliveryOrderUpdate = "delivery/update/status";
export const getNextDeliveryOrderNumber = "sequence/getNextSequence?prefix=DO";
export const searchDOByCriteria = (pageNo: number) =>
  `delivery/search?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const deliveryPrintData = (deliveryNum: string) =>
  `delivery/preview?deliveryNum=${deliveryNum}`;

//Picking
export const pickingOrderList = "order/picking/list";
export const pickingOrderSave = "order/picking/create";
export const pickingOrderUpdateById = (id: number) =>
  `order/picking/update/${id}`;
export const pickingOrderUpdate = "order/picking/update/status";
export const getNextPickingOrderNumber = "sequence/getNextSequence?prefix=DO";
export const pickingOrderListByStatus =
  "order/picking/fetchOrderPickingsByStatus";
export const orderPickingProducts = (id: string) =>
  `delivery/fetchOrderPickingDetails/${id}`;
export const searchOPByCriteria = (pageNo: number) =>
  `order/picking/search?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;

//sampling
export const SamplingList = (pageNo: number) =>
  `sampling/list?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const samplingStatusUpdate = "sampling/updateStatus";
export const samplingSave = "sampling/create";
export const BatchList = "goodsReceipts/fetchBatchDetailsByStatus";
export const samplingList = "sampling/list";
export const samplingUpdateById = (id: number) => `sampling/update/${id}`;
export const getNextSaNumber = "sequence/getNextSequence?prefix=QO";

//Batch Management
export const BatchAllList = "batchDetails/search";
export const batchManagementUpdateById = (id: number) =>
  `batchDetails/updateBatch/${id}`;

//Dashboard
export const getRevenueAndUnitsSold = (timePeriod: number) =>
  `dashboard/getRevenueAndUnitsSold?timePeriodInMonths=${timePeriod}`;
export const getBestSellersData = "dashboard/getBestSellersData";
export const getTodayOrdersShippingDetails =
  "dashboard/getTodayOrdersShippingDetails";
export const getShelvesOverview = "dashboard/getShelves/overview";
export const getAislesLocationsUsage = (warehouseId: number) =>
  `dashboard/getAislesLocationsUsage/${warehouseId}`;

//Order Picking
export const OrderPickingList = (pageNo: number) =>
  `order/picking/list?pageNum=${pageNo}&pageSize=${MAX_PAGE_RECORD_SIZE}`;
export const WarehouseTransferList = "transfer/order/list";
export const ProductsBySO = (so: string) =>
  `order/picking/fetchSaleOrderProducts/${so}`;
export const ProductLocations = (type: any, prId: any, qty: any, whId: any) =>
  `order/picking/fetchProductsByPickingType?pickingType=${type}&prodId=${prId}&quantity=${qty}&warehouseId=${whId}`;
export const createOrderPicking = "order/picking/create";
export const createNewTransferOrderWarehouse = "transfer/order/create";
export const getNextOpNumber = "sequence/getNextSequence?prefix=OP";
export const getNextTONumber = "sequence/getNextSequence?prefix=TO";
export const updateOrderPicking = (id: number) => `order/picking/update/${id}`;
export const updateTransferWarehouse = (id: number) =>
  `transfer/order/update/${id}`;
export const cancelLocationDetails = (
  locationId: string,
  batchNo: string,
  prodId: string,
  qty: string,
  batchExp: string
) =>
  `op/cancel/getLocationDetails/${locationId}/${batchNo}/${prodId}/${qty}/${batchExp}`;
export const updateLocationQtyCancel = (id: string, qty: string) =>
  `op/cancel/update/location/quantity/${id}/${qty}`;
export const updateLocationDetails = `op/cancel/save/location`;
export const validateIfCancelIsEligible = (pickingNum: string) =>
  `/op/cancel/verifyOp/${pickingNum}`;

export const getcancelPrefLocationDetails = (
  batchNo: string,
  prod: string,
  batchExpiry: string,
  qty: string,
  warehouseId: string
) =>
  `op/cancel/preferedLocation/${batchNo}/${prod}/${batchExpiry}/${qty}/${warehouseId}`;

export const updateStatusToCancel = (id: string, comments: string) =>
  `op/cancel/${id}?comment=${comments}`;
export const OPStatusUpdate = `order/picking/update/status`;

export const Logs = (id: string) => `auditLog/getAuditHistoryByEntityId/${id}`;
