/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink, useLocation } from "react-router-dom";
import { RoutingConstants } from "../../common/constants/routingConstants";
import { LabelConstants } from "../../common/constants/labelConstants";
import FeatherIcon from "feather-icons-react";
import {
  ReportsData,
  SettingsData,
} from "../../common/constants/generalConstants";
import { useState } from "react";
import useAuth from "../../controllers/hooks/useAuth";

const SideNavComponent = () => {
  const location = useLocation();
  const { isAccessDisabled } = useAuth();
  const isChildRouteActive = (routes) => {
    return routes.some((route) => location.pathname === route);
  };
  const [openMenu, setOpenMenu] = useState(null);
  const handleMenuClick = (menuName) => {
    setOpenMenu(openMenu === menuName ? null : menuName);
  };

  return (
    <aside className="main-sidebar">
      <section className="sidebar position-relative">
        <div className="multinav">
          <div
            className="multinav-scroll d-flex flex-column justify-content-between"
            style={{ height: "100%" }}
          >
            <ul className="sidebar-menu m-0 p-0" data-widget="tree">
              <li
                className={`${
                  location.pathname === RoutingConstants.dashboard
                    ? "active"
                    : ""
                }`}
                onClick={() => handleMenuClick("dashboard")}
              >
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  to={RoutingConstants.dashboard}
                  title={LabelConstants.Dashboard}
                >
                  <FeatherIcon icon="monitor" />{" "}
                  <span>{LabelConstants.Dashboard}</span>
                </NavLink>
              </li>
              {!isAccessDisabled("WH", "visible") && (
                <li
                  className={`${
                    location.pathname === RoutingConstants.warehouse
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleMenuClick("warehouse")}
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={RoutingConstants.warehouse}
                    title={LabelConstants.Warehouse}
                  >
                    <FeatherIcon icon="package" />{" "}
                    <span>{LabelConstants.Warehouse}</span>
                  </NavLink>
                </li>
              )}
              {!isAccessDisabled("OI", "visible") && (
                <li
                  className={`treeview ${
                    openMenu === "orderIn" ||
                    isChildRouteActive([
                      RoutingConstants.purchase,
                      RoutingConstants.grn,
                    ])
                      ? "menu-open"
                      : ""
                  }`}
                >
                  {!isAccessDisabled("OI", "visible") && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleMenuClick("orderIn")}
                    >
                      <FeatherIcon icon="truck" />
                      <span>{LabelConstants.OrderIn}</span>
                      <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span>
                    </a>
                  )}
                  <ul
                    className="treeview-menu"
                    style={{
                      display: `${
                        openMenu === "orderIn" ||
                        isChildRouteActive([
                          RoutingConstants.purchase,
                          RoutingConstants.grn,
                        ])
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {!isAccessDisabled("OI", "visible", true, "PO") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.purchase}
                          title={LabelConstants.PurchaseOrder}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.PurchaseOrder}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("OI", "visible", true, "GRN") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.grn}
                          title={LabelConstants.GRN}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.GRN}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {!isAccessDisabled("BAMAGT", "visible") && (
                <li
                  className={`${
                    location.pathname === RoutingConstants.batchmanagement
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleMenuClick("batchManagement")}
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={RoutingConstants.batchmanagement}
                    title={LabelConstants.BatchManagement}
                  >
                    <FeatherIcon icon="box" />{" "}
                    <span> {LabelConstants.BatchManagement} </span>
                  </NavLink>
                </li>
              )}

              {!isAccessDisabled("SAM", "visible") && (
                <li
                  className={`${
                    location.pathname === RoutingConstants.sampling
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleMenuClick("sampling")}
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={RoutingConstants.sampling}
                    title={LabelConstants.Sampling}
                  >
                    <FeatherIcon icon="thermometer" />{" "}
                    <span> {LabelConstants.Sampling}</span>
                  </NavLink>
                </li>
              )}
              {!isAccessDisabled("OT", "visible") && (
                <li
                  className={`treeview ${
                    openMenu === "orderOut" ||
                    isChildRouteActive([
                      RoutingConstants.sales_order,
                      RoutingConstants.orderPicking,
                      RoutingConstants.delivery_order,
                    ])
                      ? "menu-open"
                      : ""
                  }`}
                >
                  {!isAccessDisabled("OT", "visible") && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleMenuClick("orderOut")}
                    >
                      <FeatherIcon icon="truck" />
                      <span>{LabelConstants.OrderOut}</span>
                      <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span>
                    </a>
                  )}
                  <ul
                    className="treeview-menu"
                    style={{
                      display: `${
                        openMenu === "orderOut" ||
                        isChildRouteActive([
                          RoutingConstants.sales_order,
                          RoutingConstants.orderPicking,
                          RoutingConstants.delivery_order,
                        ])
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {!isAccessDisabled("OT", "visible", true, "SO") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.sales_order}
                          title={LabelConstants.SalesOrder}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.SalesOrder}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("OT", "visible", true, "OP") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.orderPicking}
                          title={LabelConstants.OrderPicking}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.OrderPicking}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("OT", "visible", true, "DO") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.delivery_order}
                          title={LabelConstants.DeliveryOrder}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.DeliveryOrder}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {!isAccessDisabled("RO", "visible") && (
                <li
                  className={`${
                    location.pathname === RoutingConstants.returnorders
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleMenuClick("returns")}
                >
                  <NavLink
                    className={({ isActive }) => (isActive ? "active" : "")}
                    to={RoutingConstants.returnorders}
                    title={LabelConstants.Returns}
                  >
                    <FeatherIcon icon="corner-down-left" />{" "}
                    <span> {LabelConstants.Returns}</span>
                  </NavLink>
                </li>
              )}
              {!isAccessDisabled("ST", "visible") && (
                <li
                  className={`treeview ${
                    openMenu === "stockTransfer" ||
                    isChildRouteActive([RoutingConstants.warehouseTransfer])
                      ? "menu-open"
                      : ""
                  }`}
                >
                  {!isAccessDisabled("ST", "visible") && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleMenuClick("stockTransfer")}
                    >
                      <FeatherIcon icon="truck" />
                      <span>{LabelConstants.StockTransfer}</span>
                      <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span>
                    </a>
                  )}
                  <ul
                    className="treeview-menu"
                    style={{
                      display: `${
                        openMenu === "stockTransfer" ||
                        isChildRouteActive([RoutingConstants.warehouseTransfer])
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {!isAccessDisabled("ST", "visible", true, "W2W") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.warehouseTransfer}
                          title={LabelConstants.WarehouseToWarehouse}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.WarehouseToWarehouse}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("ST", "visible", true, "L2L") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.warehousel2l}
                          state={{ tabIndex: 1 }}
                          title={LabelConstants.LocationToLocation}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.LocationToLocation}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {!isAccessDisabled("MD", "visible") && (
                <li
                  className={`treeview ${
                    openMenu === "masters" ||
                    isChildRouteActive([
                      RoutingConstants.supplier,
                      RoutingConstants.customer,
                      RoutingConstants.transporter,
                      RoutingConstants.employee,
                    ])
                      ? "menu-open"
                      : ""
                  }`}
                >
                  {!isAccessDisabled("MD", "visible") && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleMenuClick("masters")}
                    >
                      <FeatherIcon icon="package" />
                      <span>Masters</span>
                      <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span>
                    </a>
                  )}
                  <ul
                    className="treeview-menu"
                    style={{
                      display: `${
                        openMenu === "masters" ||
                        isChildRouteActive([
                          RoutingConstants.supplier,
                          RoutingConstants.customer,
                          RoutingConstants.transporter,
                          RoutingConstants.employee,
                        ])
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {!isAccessDisabled("MD", "visible", true, "SUP") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.supplier}
                          title={LabelConstants.Supplier}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.Supplier}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("MD", "visible", true, "CUST") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.customer}
                          title={LabelConstants.Customer}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.Customer}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("MD", "visible", true, "TRANS") && (
                      <li
                        className={`${
                          location.pathname === RoutingConstants.transporter
                            ? "active"
                            : ""
                        }`}
                      >
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.transporter}
                          title={LabelConstants.Transporter}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.Transporter}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("MD", "visible", true, "EMP") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.employee}
                          title={LabelConstants.Employee}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.Employee}
                        </NavLink>
                      </li>
                    )}
                    {!isAccessDisabled("MD", "visible", true, "PROD") && (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={RoutingConstants.product}
                          title={LabelConstants.Products}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {LabelConstants.Products}
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {!isAccessDisabled("REP", "visible") && (
                <li
                  className={`treeview ${
                    openMenu === "reports" ||
                    isChildRouteActive([
                      RoutingConstants.stk_reports,
                      RoutingConstants.sp_reports,
                      RoutingConstants.po_reports,
                      RoutingConstants.gm_reports,
                      RoutingConstants.gr_reports,
                      RoutingConstants.supplier_reports,
                      RoutingConstants.customer_reports,
                      RoutingConstants.transporter_reports,
                      RoutingConstants.product_reports,
                    ])
                      ? "menu-open"
                      : ""
                  }`}
                >
                  {!isAccessDisabled("REP", "visible") && (
                    <a
                      href="javascript:void(0)"
                      onClick={() => handleMenuClick("reports")}
                    >
                      <FeatherIcon icon="pie-chart" />
                      <span>Reports</span>
                      <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span>
                    </a>
                  )}
                  <ul
                    className="treeview-menu"
                    style={{
                      display: `${
                        openMenu === "reports" ||
                        isChildRouteActive([
                          RoutingConstants.stk_reports,
                          RoutingConstants.sp_reports,
                          RoutingConstants.po_reports,
                          RoutingConstants.gm_reports,
                          RoutingConstants.gr_reports,
                          RoutingConstants.supplier_reports,
                          RoutingConstants.customer_reports,
                          RoutingConstants.transporter_reports,
                          RoutingConstants.product_reports,
                        ])
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {ReportsData?.map(
                      (data) =>
                        !isAccessDisabled("REP", "visible", true, data.key) && (
                          <li>
                            <NavLink
                              className={({ isActive }) =>
                                isActive ? "active" : ""
                              }
                              to={data?.path}
                              title={data?.name}
                            >
                              <i className="icon-Commit">
                                <span className="path1"></span>
                                <span className="path2"></span>
                              </i>
                              {data?.name}
                            </NavLink>
                          </li>
                        )
                    )}
                  </ul>
                </li>
              )}
              {JSON.parse(window.localStorage.getItem("contextInfo"))?.data
                ?.designation === "SUPERADMIN" && (
                <li
                  className={`treeview ${
                    openMenu === "settings" ||
                    isChildRouteActive([RoutingConstants.setup_settings])
                      ? "menu-open"
                      : ""
                  }`}
                >
                  <a
                    href="javascript:void(0)"
                    onClick={() => handleMenuClick("settings")}
                  >
                    <FeatherIcon icon="settings" />
                    <span>Settings</span>
                    <span className="pull-right-container">
                      <i className="fa fa-angle-right pull-right"></i>
                    </span>
                  </a>
                  <ul
                    className="treeview-menu"
                    style={{
                      display: `${
                        openMenu === "settings" ||
                        isChildRouteActive([RoutingConstants.setup_settings])
                          ? "block"
                          : "none"
                      }`,
                    }}
                  >
                    {SettingsData?.map((data) => (
                      <li>
                        <NavLink
                          className={({ isActive }) =>
                            isActive ? "active" : ""
                          }
                          to={data?.path}
                          title={data?.name}
                        >
                          <i className="icon-Commit">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </i>
                          {data?.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              )}
            </ul>

            <div className="sidebar-widgets">
              <div className="copyright text-center m-25 text-white-50">
                <p>
                  <strong className="d-block">Consult Veda</strong> ©{" "}
                  {new Date().getFullYear()} All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </aside>
  );
};
export default SideNavComponent;
